import { Component } from '@angular/core';
import { BroadcastService } from './shared/services/broadcast.service';
import { GlobalService } from './shared/services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'QueueManagementSystem';
  constructor(private broadcastService: BroadcastService, private _globalService: GlobalService) {
    //sessionStorage.setItem()
  }

  public ngOnInit() {
    this.broadcastService.initialize();
    this.broadcastService.Notification.subscribe(data => {
      // do some sort of notification!
      //console.log(data); // <-- This will be the message that has come from your NotificationHub!
      this._globalService.dataBusChanged('tokenChange', data);
    });
    this.broadcastService.TotalToken.subscribe(data => {
      // do some sort of notification!
      //console.log(data); // <-- This will be the message that has come from your NotificationHub!
      this._globalService.dataBusChanged('totalToken', data);
    });
    this.broadcastService.GetNextTokenValue.subscribe(data => {      
      // do some sort of notification!
      //console.log(data); // <-- This will be the message that has come from your NotificationHub!
      this._globalService.dataBusChanged('getNextTokenValue', data);
    });
    this.broadcastService.GetNextCounterValue.subscribe(data => {
      // do some sort of notification!
      //console.log(data); // <-- This will be the message that has come from your NotificationHub!
      this._globalService.dataBusChanged('getNextCounterValue', data);
    });
    this.broadcastService.GetUpdateCounterValue.subscribe(data => {
      // do some sort of notification!
      //console.log(data); // <-- This will be the message that has come from your NotificationHub!
      this._globalService.dataBusChanged('getCounterData', data);
    });
    this.broadcastService.GetSkippedTokenValue.subscribe(data => {
      // do some sort of notification!
      //console.log(data); // <-- This will be the message that has come from your NotificationHub!
      this._globalService.dataBusChanged('activateToken', data);
    });
  }
}
