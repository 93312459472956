import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { Observable } from 'rxjs';
import { ProcessFields, SMSProcessFields } from '../../shared/models/processfields-model';
import { QMSTokenModel } from '../../shared/components/QMSToken/QMSToken';
import { UtilityService } from '../../shared/services/utility.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Configuration } from 'src/app/shared/roots/configuration.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../../shared/services/global.service';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-cancelappointment',
  templateUrl: './cancelappointment.component.html',
  styleUrls: ['./cancelappointment.component.css']
})
export class CancelappointmentComponent implements OnInit {
  myDate = new Date();
  constructor(private dataService: DataService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private configuration: Configuration,
    private spinner: NgxSpinnerService,
    private _globalService: GlobalService,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef/*used for get appointment api call*/
  ) {
    this._globalService.data$.subscribe(data => {

    }, error => {
      console.log('Error: ' + error);
      });
  }

  //@ViewChild('customerForm') public Form: NgForm;
  public storeID: any;
  public ChildEntityKey: string;
  public ChildEntityKey1: string;
  public cancelAppointmentsList = null;
  public allOpenTokens: any;
  public showConfirmButton: boolean = false;
  public showCancelButton: boolean = false;
  public appointmentstartdate: Date;
  public appointmentenddate: Date;
  public showAppointmentData: boolean = false;
  public QMSToken = {} as QMSTokenModel;
  public isShow: boolean = false;
  config: any;
  collection = { count: 60, data: [] };
  public dataList: any[];
  @Input() AllOpenTokens;
  @Output() OpenTokenEvent = new EventEmitter();
  public isUpdateOperationVisible: boolean = true;
  public isGetOperationVisible: boolean = false;
  public isActiveClicked: boolean = false;
  public isMasterSel: boolean;
  public checkedDataList: any;
  public result: any;
  public sms: any;
  public smsFields: any;
  public showStoreName: any;

  ngOnInit() {
    this.dataService.getAuthKey().subscribe(data => {
      sessionStorage.setItem('requestToken', data.toString());
    })
    this.ShowAppointmentsOnPageLoad();
  }

  // to get all open Appointments on show appointments 
  public ShowAppointments() {
    var $context = this;
    this.cancelAppointmentsList = null;
    var processFields = new ProcessFields();
    this.storeID = sessionStorage.getItem('storeID');//2;
    this.ChildEntityKey = this.appointmentstartdate.toString(); //"2020-04-30";
    this.ChildEntityKey1 = this.appointmentenddate.toString();// "2020-05-02";
    processFields.EntityID = this.storeID;
    processFields.ChildEntityKey = this.ChildEntityKey;
    processFields.ChildEntityKey1 = this.ChildEntityKey1;
    this.dataService.add<QMSTokenModel[]>('QMSToken/getAllOpenAppointments', processFields).subscribe(data => {
      this.cancelAppointmentsList = data;
      this.showAppointmentData = true;
      
      for (var i = 0; i < this.cancelAppointmentsList.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            value: "items number " + (i + 1)
          }
        );
      }
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.cancelAppointmentsList.count
      };
      if (data.length > 0) {
        
        this.showConfirmButton = true;
        this.dataList = [];
        this.cancelAppointmentsList.forEach(function (value, index) {
          $context.dataList.push(value);
          $context.dataList[index].isSelected = false;
          $context.dataList[index].emailId = sessionStorage.getItem('emailId');
        });
        this.cdr.detectChanges();
      }
      else {
        this.dataList = [];
        this.showConfirmButton = false;
      }
  
    }, function () {
     // this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public ShowAppointmentsOnPageLoad() {
    var $context = this;
    this.cancelAppointmentsList = null;
    var processFields = new ProcessFields();
    this.storeID = sessionStorage.getItem('storeID');//2;
    this.ChildEntityKey = new Date().toJSON().slice(0, 10).replace(/-/g, '/'); //"2020-04-30";
    this.ChildEntityKey1 = new Date().toJSON().slice(0, 10).replace(/-/g, '/');// "2020-05-02";
    //this.ChildEntityKey = this.appointmentstartdate.toString(); //"2020-04-30";
    //this.ChildEntityKey1 = this.appointmentenddate.toString();// "2020-05-02";
    processFields.EntityID = this.storeID;
    processFields.ChildEntityKey = this.ChildEntityKey;
    processFields.ChildEntityKey1 = this.ChildEntityKey1;
    this.dataService.add<QMSTokenModel[]>('QMSToken/getAllOpenAppointments', processFields).subscribe(data => {
      this.cancelAppointmentsList = data;
      this.showAppointmentData = false;
      console.log(this.cancelAppointmentsList );
      for (var i = 0; i < this.cancelAppointmentsList.count; i++) {
        this.collection.data.push(
          {
            id: i + 1,
            value: "items number " + (i + 1)
          }
        );
      }
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.cancelAppointmentsList.count
      };
      if (data.length > 0) {

        this.showConfirmButton = true;
        this.dataList = [];
        this.cancelAppointmentsList.forEach(function (value, index) {
          $context.dataList.push(value);
          $context.dataList[index].isSelected = false;
          $context.dataList[index].emailId = sessionStorage.getItem('emailId');
        });
        this.cdr.detectChanges();
      }
      else {
        this.dataList = [];
        this.showConfirmButton = false;
      }

    }, function () {
      // this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
    var processFields = new ProcessFields();
    processFields.EntityKey = sessionStorage.getItem('storeID');
    this.dataService.add<QMSTokenModel[]>('QMSToken/getStoreName', processFields).subscribe(data => {
      this.showStoreName = data;

    }, function () {
      this.utilityService.showFailMessage.fire('Error!. Please try again.');
    }
    );
  }

  checkUncheckAll() {
    for (var i = 0; i < this.dataList.length; i++) {
      this.dataList[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedDataList = [];
    for (var i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].isSelected)
        this.checkedDataList.push(this.dataList[i]);
    }
    this.checkedDataList = JSON.stringify(this.checkedDataList);
  }

  public CanceTokens() {
    this.checkedDataList = [];
    for (var i = 0; i < this.dataList.length; i++) {
      if (this.dataList[i].isSelected)
        this.checkedDataList.push(this.dataList[i]);
    }
    var data = {
      tokenList: this.checkedDataList
    }
    this.sms = this.checkedDataList;

    this.utilityService.showConfirmationMessage('Are your sure you want to Cancel the selected Token(s)?').then((result) => {
      if (result.value) {
        this.dataService.add<any>('QMSToken/cancelToken', data).subscribe(data => {
          this.result = data;
          if (this.result == 'Success') {
            var _this = this;
            _this.SMSTemplate();
            _this.ShowAppointments();
          }
          else {
          }
        }, function () {
          // this.utilityService.showFailMessage('Error!. Please try again.');
        }
        );
      }
    });  
  }

  public setCancelToken(tno) {
    this.OpenTokenEvent.emit(tno);
    this.isShow = false;
    this.isActiveClicked = true;
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  public SMSTemplate() {

    var ProcessSMSFields = {
      ProcessSMSFields: this.sms
    }
    this.dataService.addFlexLink<SMSProcessFields[]>('smsconfiguration/insertcancelLog', ProcessSMSFields).subscribe(data => { });
  }
}
