import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { UserModel } from '../../component/userinfo/user-model';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';

@Injectable()
export class GlobalService {

  private dataSource = new Subject<DataSourceClass>();

  data$ = this.dataSource.asObservable();

  public dataBusChanged(ev, value) {
    this.dataSource.next({
      ev: ev,
      value: value
    });
  }

  public _loggedUserModel: UserModel;

  get loggedUserModel(): UserModel {
    return this._loggedUserModel;
  }
  set loggedUserModel(loggedUserModel: UserModel) {
    this._loggedUserModel = loggedUserModel;
  }
}


export class DataSourceClass {
  ev: string;
  value: any;
}
