import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from 'src/app/shared/services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadService } from 'src/app/shared/file-upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { AppfielddefinitionService } from '../appfielddefinition/appfielddefinition.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/shared/services/utility.service';
//import { RequestOptions } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/shared/roots/configuration.component';
//import { Headers } from '@angular/http';

import { HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'
import { FileUploader } from 'ng2-file-upload';
import { FileImportModel } from './file-import-model';
import { ProcessFields } from 'src/app/shared/models/processfields-model';


const URL = '';

@Component({
  selector: 'app-fileimport',
  templateUrl: './fileimport.component.html',
  styleUrls: ['./fileimport.component.css']
})
export class FileimportComponent implements OnInit {

  private actionUrl: string;
  @Input() pageName: any;
  constructor(private dataService: DataService, private _translate: TranslateService,
    private fileuploadService: FileUploadService, private modalService: NgbModal,
    private utilityService: UtilityService, private router: Router, /*private appfielddefinitionService: AppfielddefinitionService,*/ private configuration: Configuration) {

    this.actionUrl = configuration.serverWithApiUrl;
  }


  private _parentID: number;
  private _childID: number;
  @Input() apiUrl: string;
  //@Input() apiUrl: string;

  @Input()
  set parentID(parentID: number) {
    this._parentID = parentID;
  }
  get parentID(): number {
    return this._parentID;
  }
  @Input()
  set childID(childID: number) {
    this._childID = childID;
  }
  get childID(): number {
    return this._childID;
  }

  @Output() childEvent = new EventEmitter();
  fileuploaded: boolean = false;

  public selectedFile: Array<FileImportModel> = [];
  public uploader: FileUploader = new FileUploader({ url: URL });
  public hasBaseDropZoneOver: boolean = false;
  public unit: string;
  public size: number;
  public appFieldData: any;
  public appFieldFileSize: any;
  public appFieldFileType: any;
  public appFieldFileTypeList: any;
  public isDisabled: boolean = false;
  public fileContent: any;
  public fileName: any;
  public fileType: any;
  public fileSize: any;
  public errorMessages: any;
  public fileDescription: any;
  public purgeSummary: any;
  public purgeSummaryColumnsData = [{ columnName: "excelRowId", title: "Excel Row" },
  { columnName: "result", title: "Result" },
  { columnName: "message", title: "Message" }, { columnName: "skidNumber", title: "BaaN Physical Skid ID Number" }];

  ngOnInit() {
    var _this = this;
    this.getAllAppFieldDefinition();
    console.log(this.pageName);
    this.uploader.onWhenAddingFileFailed = function (item) {
      var InvalidFileErrorMsg = _this._translate.instant('Common.Message.Error.Invalid_File_Type') + ': .' + item.name.split('.')[1];
      _this.utilityService.showFailMessage(InvalidFileErrorMsg);
    }
    this.uploader.onAfterAddingFile = function (item) {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        _this.selectedFile.push({
          FileContent: reader.result.toString(),
          FileName: item.file.name,
          FileType: item.file.type,
          FileSize: item.file.size,
          FileDescription: ""
        });
      };
      reader.readAsDataURL(item._file);

      this.size = item.file.size;
      if (this.size < 1000) {
        this.size = item.file.size;
        this.unit = " BYTES";
      } else if (this.size < 1000 * 1000) {
        this.size = item.file.size / 1000;
        this.unit = " KB";
      } else if (this.size < 1000 * 1000 * 1000) {
        this.size = item.file.size / 1000 / 1000;
        this.unit = " MB";
      } else {
        this.size = item.file.size / 1000 / 1000 / 1000;
        this.unit = " GB";
      }
      item.file.size = this.size.toFixed(2) + this.unit;
    }

  }

  public downloadTemplate() {
    if (this.pageName != 'userBulkUpload') { var url = this.actionUrl + "fileimport/download?pageName=SkidList"; }
    else { var url = this.actionUrl + "fileimport/download?pageName=BulkUser"; }

    window.open(url);
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public uploadAll(): void {
    var _data: any;
    _data = new Object();
    if (this.pageName == 'userBulkUpload') {
      _data.pageName = "BulkUser";
    } else {
      _data.pageName = "SkidList";
    }
    _data.parentID = this.parentID;
    _data.childID = this.childID;
    var currentFile = this.selectedFile.length - 1;
    _data.fileContent = this.selectedFile[currentFile].FileContent;
    _data.fileName = this.selectedFile[currentFile].FileName;
    _data.fileType = this.selectedFile[currentFile].FileType;
    _data.fileSize = this.selectedFile[currentFile].FileSize;
    _data.fileDescription = this.selectedFile[currentFile].FileDescription;
    _data.fileExtension = '.xlsx'

    if (this.selectedFile.length > 0) {

      this.dataService.add<any>("fileimport/save", _data).subscribe((data) => {
        //this.fileuploadService.setFileData(data);
        //var FileLogID = data.split('|')[1];
        //sessionStorage.setItem("FileUploadLogID", FileLogID);
        if (data.indexOf('successfully') >= 0) {


          this.utilityService.showSaveSuccessMessage(data.split('|')[0]);
          //this.getFileSummary(FileLogID);
          //this.onChange(true, '');
          //this.modalService.dismissAll();
        }
        else if (data.indexOf('Failwhileprocessing') >= 0) {
          this.utilityService.showFailMessage(this._translate.instant('FileImport.Message.Result.Fail_While_Processing'));
          //this.getFileSummary(FileLogID);
          sessionStorage.removeItem('FileUploadLogID');
        }
        else if (data.indexOf('File_Empty') >= 0) {
          this.utilityService.showFailMessage(this._translate.instant('FileImport.Message.Result.File_Empty'));
        }
        else if (data.indexOf('DuplicateSKIDNumber') >= 0) {
          this.utilityService.showFailMessage(this._translate.instant('FileImport.Message.Result.DuplicateClaimKeyTicketNumber'));
        }
        else if (data.indexOf('File_Being_Processed') >= 0) {
          this.utilityService.showFailMessage(this._translate.instant('FileImport.Message.Result.File_Being_Processed'));
          //this.getFileSummary(FileLogID);
        }
        else {
          this.utilityService.showFailMessage(this._translate.instant('FileImport.Message.Result.Fail_While_Processing'));
          //this.getFileSummary(FileLogID);

        }
      },
        error => {
          this.utilityService.showFailMessage();
        },
        () => {

        });

    }
    else {
      this.utilityService.showFailMessage(this._translate.instant('Common.Message.Error.FileEmpty'));
    }
  }

  public getFileSummary(fileUploadId) {
    var processFields = new ProcessFields();
    processFields.EntityID = fileUploadId;
    this.dataService.getAll<any>('fileimport/GetFileSummary', processFields).subscribe(data => {
      //console.log(data);
      this.purgeSummary = data;
    });
  }

  public remove(): void {
    this.uploader.clearQueue();
    this.selectedFile.length = 0;
  }

  public clearQueue(): void {
    this.modalService.dismissAll();
  }

  public onChange(fileuploaded, data) {
    this.childEvent.emit(fileuploaded);
    this.modalService.dismissAll();
  }


  public getAllAppFieldDefinition() {
    //this.appfielddefinitionService.getAllAppFieldDefination().subscribe(data => {
    //  this.appFieldData = data;
    //  for (let index in this.appFieldData) {
    //    if (this.appFieldData[index].fieldKey == "File_Upload_Size")
    //      this.appFieldFileSize = this.appFieldData[index].fieldValue;

    //    if (this.appFieldData[index].fieldKey == "File_Upload_Type") {
    //      this.appFieldFileType = this.appFieldData[index].fieldValue;
    //      this.appFieldFileTypeList = this.appFieldFileType.split(',');
    //    }
    //  }

    this.uploader.setOptions({
      url: this.apiUrl,
      maxFileSize: (1024 * 1024 * this.appFieldFileSize),
      allowedMimeType: this.appFieldFileTypeList
    });
    //});
  }

  //public textareaValueChange($event) {
  //  var _this = $event.currentTarget;
  //  var index = _this.className.split('-')[0];
  //  this.selectedFile[index].FileDescription = _this.value;
  //}
}





