
<div class="form-group col-sm-12">
  <div *ngFor="let item of allCounters" style="border: 1px solid #61ab2e; padding:10px; width: 18%; height: 220px; text-align: center;
    background: #76bd37; float:left; margin-right:20px; margin-top:10px;">
    <h3 style="margin-top:0px;">Counter-{{item.counterNo}}</h3>
    <label style="margin-top:10px; margin-bottom:0px;">Token Number</label>
    
    <h1 id="Counter-{{item.counterNo}}" style="font-size:4vw; padding:0px; margin-top:10px">{{item.tokenNo}}</h1>
  </div>
</div>

