<div class="container table-responsive" *ngIf="AllSkippedTokens">
  <div *ngIf="isShow">
    <table class="table">
      <caption>List of Skipped Tokens</caption>
      <thead>
        <tr>
          <th>Token Number</th>
          <th>Mobile Number</th>
          <th>Customer Name</th>
          <th>Brand Name</th>
          <th>Category Name</th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of AllSkippedTokens | paginate: config">

          <td>{{item.tokenNo}}</td>
          <td>{{item.mobileNo}}</td>
          <td>{{item.customerName}}</td>
          <td>{{item.brandName}}</td>
          <td>{{item.categoryName}}</td>
          <td><button type="button" class="btn btn-info btn-margin" style="display:block; margin:auto" (click)="setActiveToken([item.tokenNo,item.mobileNo,item.customerName,item.brandName,item.tokenID,item.categoryName])">Active</button></td>
        </tr>
      </tbody>
    </table>
    <pagination-controls (pageChange)="pageChanged($event)" class="table-responsive"></pagination-controls>
  </div>
</div>

