import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerComponent } from './component/customer/customer.component';
import { ReceptionistComponent } from './component/receptionist/receptionist.component';
import { AuthenticationComponent } from './component/authentication.component';
import { DisplayTokenComponent } from './component/display-token/display-token.component';
import { ErrorpageComponent } from './component/errorpage/errorpage.component';
import { ReleaseCountersComponent } from './component/release-counters/release-counters.component';
import { AppointmentComponent } from './component/appointment/appointment.component';
import { HolidayLoaderComponent } from './component/holiday-loader/holiday-loader.component';
import { FileimportComponent } from './component/fileimport/fileimport.component';
import { CancelappointmentComponent } from './component/cancelappointment/cancelappointment.component';

const routes: Routes = [
  { path: 'login', component: CustomerComponent }
  , { path: 'customer', component: CustomerComponent }
  , { path: 'receptionist', component: ReceptionistComponent}
  , { path: '', component: AuthenticationComponent }
  , { path: 'displayToken/:id', component: DisplayTokenComponent }
  , { path: 'errorPage', component: ErrorpageComponent }
  , { path: 'releaseCounters', component: ReleaseCountersComponent }
  , { path: 'appointment', component: AppointmentComponent }
  , { path: 'appointment/:hash', component: AppointmentComponent }
  , { path: 'holidayLoader', component: HolidayLoaderComponent }
  , { path: 'fileimport', component: FileimportComponent }
  , { path: 'cancelappointment', component: CancelappointmentComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
