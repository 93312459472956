<header class="header">
  <ul class="topnav">
    <li style="float:left">
        <img [src]="selectedBrandLogoPaths" style="width:150px;height:50px;"  /> 
    </li>
    <li class="flex-logo-div1" style="float:right;"><h1>FLEXLINK</h1></li>
  </ul>
</header>
<div>
  <br />
</div>
<div class="btn-toolbar" style="justify-content:flex-end; display: flex;" role="group">
  <button type="button" class="btn btn-info btn-margin" style="text-align:left; font-size:15px; border-radius:10px" (click)="SaveToken()" *ngIf="showConfirmButton">
    Confirm Appointment
  </button>&nbsp;&nbsp;
  <button type="button" class="btn btn-info btn-margin" style="font-size:15px; border-radius:10px" (click)="resetFields()" *ngIf="showCancelButton">
    Cancel
  </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</div>
<form id="Customer" name="CustomerForm" #customerForm="ngForm">
  <div class="row controls zeromargin" style="margin-top:0px">
    <div class="col-md-4">
      <div class="card mt-3 myForm col-md-12">
        <!--<div class="row">
          <div class="col-lg-9 col-md-9 col-sm-9">
            <div class="form-group" style="float:right">
              <span class="tokenBadge">Please fill below details to book appointment</span>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
          </div>
        </div>-->
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="isbrandHash; then noDropDown else dropDown"></div>
            <ng-template #dropDown>
              <div class="form-group" [class.has-error]="brandControl.invalid && brandControl.touched" [class.has-success]="brandControl.valid">
                <label for="form_brandname" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Brand Name</label>
                <select class="form-control" name="form_brandname" #brandControl="ngModel" id="form_brandname" style="height:30px; font-size:12px"
                        [(ngModel)]="selectedBrandName" #mySelect (change)='getStates()' required>
                  <option value="">--SELECT--</option>
                  <option *ngFor="let brand of brands" [value]="brand.brandName">{{brand.brandName}}</option>
                </select>
                <div *ngIf="brandControl.invalid && (brandControl.dirty || brandControl.touched)" class="alert alert-danger">
                  <span *ngIf="brandControl.errors.required">
                    Brand is required.
                  </span>
                </div>
              </div>
            </ng-template>
            <ng-template #noDropDown>
              <div class="form-group" [class.has-error]="brandControl.invalid && brandControl.touched" [class.has-success]="brandControl.valid">
                <label for="form_brandname" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Brand Name</label>
                <select class="form-control" name="form_brandname" #brandControl="ngModel" id="form_brandname" style="height:30px; font-size:12px"
                        [(ngModel)]="selectedBrandName" [disabled]="disableBrands">
                  <!--<option value="">--SELECT--</option>-->
                  <option *ngFor="let brand of brands" [value]="brand.brandName" [selected]="brand.brandName">{{brand.brandName}}</option>
                  }<
                  <!--<option [ngValue]="brands[0].brandName">{{brands[0].brandName}}</option>-->
                </select>
                <div *ngIf="brandControl.invalid && (brandControl.dirty || brandControl.touched)" class="alert alert-danger">
                  <span *ngIf="brandControl.errors.required">
                    Brand is required.
                  </span>
                </div>
              </div>
            </ng-template>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="stateStatus">
            <div class="form-group" [class.has-error]="stateControl.invalid && stateControl.touched" [class.has-success]="stateControl.valid">
              <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
              <label for="form_statename" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>State</label>
              <!--</div>-->
              <!--<div class="col-lg-5 col-md-5 col-sm-5">-->
              <select class="form-control" name="statename" #stateControl="ngModel" id="form_statename" style="height:30px; font-size:12px"
                      [(ngModel)]="selectedStateName" #mySelect (change)='getCities(mySelect.value)' required>
                <option value="">--SELECT--</option>
                <option *ngFor="let state of states" [value]="state.stateName">{{state.stateName}}</option>
              </select>
              <div *ngIf="stateControl.invalid && (stateControl.dirty || stateControl.touched)" class="alert alert-danger">
                <span *ngIf="stateControl.errors.required">
                  State is required.
                </span>
              </div>
              <!--</div>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" [class.has-error]="cityControl.invalid && cityControl.touched" [class.has-success]="cityControl.valid" *ngIf="cityStatus">
              <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
              <label for="form_cityname" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>City</label>
              <select class="form-control" name="form_cityname" #cityControl="ngModel" id="form_cityname" style="height:30px; font-size:12px"
                      [(ngModel)]="selectedCityName" #myAsp (change)='getAsp()' required>
                <option value="">--SELECT--</option>
                <option *ngFor="let city of cities" [value]="city.cityName">{{city.cityName}}</option>
              </select>
              <div *ngIf="cityControl.invalid && (cityControl.dirty || cityControl.touched)" class="alert alert-danger">
                <span *ngIf="cityControl.errors.required">
                  City is required.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" [class.has-error]="aspControl.invalid && aspControl.touched" [class.has-success]="aspControl.valid" *ngIf="aspStatus">
              <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
              <label for="form_aspname" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Store Name</label>
              <select class="form-control" name="form_aspname" #aspControl="ngModel" id="form_aspname" style="height:30px; font-size:12px"
                      [(ngModel)]="selectedaspID" #myCat (change)='getCategories()' required>
                <option value="">--SELECT--</option>
                <option *ngFor="let item of asp" [value]="item.localStoreId">{{item.storeName + ", " + item.address1 + ", " + item.address2 + ", " + item.zipCode}}</option>
              </select>
              <div *ngIf="aspControl.invalid && (aspControl.dirty || aspControl.touched)" class="alert alert-danger">
                <span *ngIf="aspControl.errors.required">
                  Store is required.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group" [class.has-error]="categoryControl.invalid && categoryControl.touched" [class.has-success]="categoryControl.valid" *ngIf="categoryStatus">
              <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
              <label for="form_categoryname" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Category</label>
              <!--</div>-->
              <!--<div class="col-lg-5 col-md-5 col-sm-5">-->
              <select class="form-control" name="categoryname" #categoryControl="ngModel" id="form_categoryname" style="height:30px; font-size:12px"
                      [(ngModel)]="selectedCategoryID" #myCust (change)='showFields()' required>
                <option value="">--SELECT--</option>
                <option *ngFor="let cat of categories" [value]="cat.categoryID">{{cat.categoryName}}</option>
              </select>
              <div *ngIf="categoryControl.invalid && (categoryControl.dirty || categoryControl.touched)" class="alert alert-danger">
                <span *ngIf="categoryControl.errors.required">
                  Category is required.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" *ngIf="customerStatus">
            <div class="form-group">
              <label for="form_customername" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Customer Name</label>
              <input id="form_customername" type="text" [(ngModel)]="QMSToken.CustomerName" name="customerName" class="form-control" placeholder="Please enter your Name *" autocomplete="off" #customernameControl="ngModel" maxlength="100" required style="height:30px; font-size:12px">
              <div *ngIf="customernameControl.invalid && (customernameControl.dirty || customernameControl.touched)" class="alert alert-danger">
                <span *ngIf="customernameControl.errors.required">
                  Name is required.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="isMobileText; then longTextBox else shortTextBox"></div>
          <ng-template #shortTextBox>
            <div class="col-md-8" *ngIf="mobileStatus">
              <div class="form-group" [class.has-error]="mobilenoControl.invalid && mobilenoControl.touched" [class.has-success]="mobilenoControl.valid">
                <label for="form_mobilenumber" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Mobile Number</label>
                <input id="form_mobilenumber" type="text" [(ngModel)]="QMSToken.MobileNo" name="mobilenumber" class="form-control" placeholder="Please enter your Mobile Number *" autocomplete="off" #mobilenoControl="ngModel" maxlength="10"
                       pattern="^[789]\d{9}$" required style="height:30px; font-size:12px" [disabled]="mobileText">
                <div *ngIf="mobilenoControl.invalid && (mobilenoControl.dirty || mobilenoControl.touched)" class="alert alert-danger">
                  <span *ngIf="mobilenoControl.errors.required">
                    Mobile Number is required.
                  </span>
                  <span *ngIf="mobilenoControl.errors.pattern">
                    Enter Correct Mobile Number
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4" *ngIf="generateOtp">
              <input type="submit" title="Please fill all mandatory details!" class="btn btn-primary" value="Generate OTP" [disabled]="customerForm.pristine || customerForm.invalid || generateOtpDisable" (click)="getOTP()" style="height:30px; font-size:12px; margin-top:25px; width:100px">
            </div>
          </ng-template>
          <ng-template #longTextBox>
            <div class="col-md-12" *ngIf="mobileStatus">
              <div class="form-group" [class.has-error]="mobilenoControl.invalid && mobilenoControl.touched" [class.has-success]="mobilenoControl.valid">
                <label for="form_mobilenumber" style="font-size: 14px;font-weight: 700;"><span style="color:red;">*</span>Mobile Number</label>
                <input id="form_mobilenumber" type="text" [(ngModel)]="QMSToken.MobileNo" name="mobilenumber" class="form-control" placeholder="Please enter your Mobile Number *" autocomplete="off" #mobilenoControl="ngModel" maxlength="10"
                       pattern="^[789]\d{9}$" required style="height:30px; font-size:12px" [disabled]="mobileText">
                <div *ngIf="mobilenoControl.invalid && (mobilenoControl.dirty || mobilenoControl.touched)" class="alert alert-danger">
                  <span *ngIf="mobilenoControl.errors.required">
                    Mobile Number is required.
                  </span>
                  <span *ngIf="mobilenoControl.errors.pattern">
                    Enter Correct Mobile Number
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>

        <div class="row" *ngIf="validateOtp">
          <div class="col-md-8">
            <input id="form_validateotp" type="text" [(ngModel)]="QMSToken.Otp" name="Otp" class="form-control" placeholder="Please enter OTP *" autocomplete="off"
                   required style="height:30px; font-size:12px" maxlength="6">
          </div>
          <div class="col-md-4">
            <input type="submit" title="Please fill all mandatory details!" class="btn btn-primary" [disabled]="customerForm.pristine || customerForm.invalid || validateOtpDisable" value="Validate OTP" (click)="validateOTP()" style="height:30px; font-size:12px; width:100px">
          </div>
        </div><br />
        <div class="row" *ngIf="showAppointmentSlots">
          <div class="col-md-12">
            <input type="submit" title="Please fill all mandatory details!" class="btn btn-primary" value="Show Appointment Slots" [disabled]="customerForm.pristine || customerForm.invalid" (click)="getAppointments()" style="height:30px; font-size:12px">
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8" *ngIf="showCalendar">
      <div class="card mt-3 myForm col-md-12">
        <calendar-scheduler-view [viewDate]="viewDate"
                                 [events]="eventsDetails"
                                 [locale]="locale"
                                 [viewDays]="viewDays"
                                 [weekStartsOn]="weekStartsOn"
                                 [excludeDays]="excludeDays"
                                 [startsWithToday]="startsWithToday"
                                 [hourSegments]="hourSegments"
                                 [dayStartHour]="dayStartHour"
                                 [dayEndHour]="dayEndHour"
                                 [dayModifier]="dayModifier"
                                 [hourModifier]="hourModifier"
                                 [segmentModifier]="segmentModifier"
                                 [eventModifier]="eventModifier"
                                 [showEventActions]="true"
                                 [showSegmentHour]="true"
                                 [zoomEventOnHover]="true"
                                 (dayHeaderClicked)="dayHeaderClicked($event.day)"
                                 (hourClicked)="hourClicked($event.hour, $event.segment, $event)"
                                 (segmentClicked)="segmentClicked('Clicked', $event.segment)"
                                 (eventClicked)="eventClicked('Clicked', $event.event)"
                                 (eventTimesChanged)="eventTimesChanged($event)"
                                 [refresh]="refresh">
        </calendar-scheduler-view>
      </div>
    </div>
  </div>
  <ng-template #timeSelectionModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="display: inline-block;" id="modal-basic-title">Event</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <span>Title</span>
            <input class="k-textbox title-input" placeholder="Title" disabled="disabled" [(ngModel)]="selectedAppointmentTime.title" name="title" style="width:90%; margin-left:10px;" />
          </div>
        </div>
      </div>
      <div>
        <span>Start</span>
        <mat-form-field appearance="fill">
          <input matInput placeholder="Choose a Date" [matDatepicker]="picker1" readonly
                 autocomplete="off" [(ngModel)]="selectedAppointmentTime.start"
                 [disabled]="true" name="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"
                                 style="height: 267px !important; width: 250px !important;">
          </mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <input type="time" matInput name="startTime" min="00:00" max="23:59" [(ngModel)]="selectedAppointmentTime.startTime" disabled="disabled" />
        </mat-form-field>
      </div><!--[(ngModel)]="selectedEntity.bondCreditDate"-->
      <div>
        <span>End</span>
        <mat-form-field appearance="fill">
          <input matInput placeholder="Choose a Date" [matDatepicker]="picker2" readonly
                 autocomplete="off" [(ngModel)]="selectedAppointmentTime.end"
                 [disabled]="true" name="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"
                                 style="height: 267px !important; width: 250px !important;">
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <input type="time" matInput name="endTime" min="00:00" max="23:59" [(ngModel)]="selectedAppointmentTime.endTime" disabled="disabled" />
        </mat-form-field>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" title="Cancel"
              class="btn btn-primary purplebg btn-margin" (click)="closeModal()">
        <i class="fa fa-times-circle-o fa-fw"></i>Cancel
      </button>
      <button type="button" title="Save"
              class="btn btn-primary purplebg btn-margin" (click)="addSlotinCalendar();">
        <i class="fa fa-times-circle-o fa-fw"></i>Save
      </button>
    </div>
  </ng-template>
</form>

