import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ProcessFields } from '../../shared/models/processfields-model';
import { GlobalService } from '../../shared/services/global.service';
import { TokenCount } from '../../shared/components/QMSToken/TokenCount';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { QMSTokenModel } from 'src/app/shared/components/QMSToken/QMSToken';


@Component({
  selector: 'app-display-token',
  templateUrl: './display-token.component.html',
  styleUrls: ['./display-token.component.css']
})
export class DisplayTokenComponent implements OnInit {
  selectedCounterID: number;
  selectedStoreID: number;

  constructor(private aRoute: ActivatedRoute, private dataService: DataService,
    private _globalService: GlobalService, private utilityService: UtilityService) {
    this._globalService.data$.subscribe(data => {
      // Continously listen to Token on display page for particular Store
      if (data.ev === 'getNextTokenValue' && data.value) {
        if (sessionStorage.getItem('storeID') == data.value.storeID) {
          var myElement = document.getElementById('Counter-' + data.value.counterNo);
          myElement.innerHTML = data.value.tokenNo.toString();
        }
      }
      // Continously listen to Token on display page for particular Store
      if (data.ev === 'activateToken' && data.value) {
        if (sessionStorage.getItem('storeID') == data.value.storeID) {
          var myElement = document.getElementById('Counter-' + data.value.counterNo);
          myElement.innerHTML = data.value.tokenNo.toString();
        }
      }
      // Continously listen to Counter on display page for particular Store
      if (data.ev === 'getCounterData' && data.value) {
        if (sessionStorage.getItem('storeID') == data.value.storeID) {
          var myElement = document.getElementById('Counter-' + data.value.counterNo);
          myElement.innerHTML = '';
        }
      }
      // Continously listen to Counter on display page for particular Store
      if (data.ev === 'getNextCounterValue' && data.value) {
        if (sessionStorage.getItem('storeID') == data.value.storeID) {
          if (data.value.counterStatus == "Active") {
            this.allCounters.push({
              counterNo: data.value.counterNo
            });
          }
          else {
            this.allCounters = this.allCounters.filter(s => s.counterNo != data.value.counterNo);
          }
        }
      }
    }, error => {
      console.log('Error: ' + error);
    });
  }

  public CurrentToken: string = '0';
  public ticketCount: number;
  public timeZone: string;
  public storeID: any;
  public applicationCode: string;
  public currentStoreID: number;
  public allCounters = [];
  public QMSToken = {} as QMSTokenModel;
  public result: any;
  public totalCounters;
  ngOnInit() {
    this.timeZone = sessionStorage.getItem('timeZone');
    this.storeID = sessionStorage.getItem('storeID');
    this.applicationCode = sessionStorage.getItem('applicationCode');
    this.currentStoreID = this.aRoute.snapshot.params["id"];
    this.getAllCounters(this.currentStoreID);
  }
  
  public getCurrentToken() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<TokenCount>('QMSToken/getCurrentToken', processFields).subscribe(data => {
      if (data) {
        this.CurrentToken = data.currentTokenNumber;
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public getAllCounters(id) {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.StoreID = id;
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<any>('QMSToken/getactiveCounters', processFields).subscribe(data => {
      if (data) {
        for (var i = 0; i < data.length; i++) {
          this.allCounters.push(data[i]);
        }
      }
    },
      error => {
      }
    );
  }
}
