import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'app-skippedtoken',
  templateUrl: './skippedtoken.component.html',
  styleUrls: ['./skippedtoken.component.css']
})
export class SkippedtokenComponent implements OnInit {
  config: any;
  collection = { count: 60, data: [] };
  @Input() AllSkippedTokens;
  @Output() SkippedTokenEvent = new EventEmitter();
  public isShow: boolean = true;
  public isUpdateOperationVisible: boolean = true;
  public isGetOperationVisible: boolean = false;
  public isActiveClicked: boolean = false;

  constructor(private _globalService: GlobalService) {
 
    //Create dummy data
    for (var i = 0; i < this.collection.count; i++) {
      this.collection.data.push(
        {
          id: i + 1,
          value: "items number " + (i + 1)
        }
      );
    }
 
    this.config = {
      itemsPerPage: 5,
      currentPage: 1
      //totalItems: this.collection.count
    };
  }
 
  pageChanged(event){
    this.config.currentPage = event;
  }

  ngOnInit() {    
    this._globalService.data$.subscribe(data => {
      if (data.ev && data.ev === 'showSkippedTokenList') {
          this.isShow = data.value;
      }
    }, error => {
      console.log('Error: ' + error);
    });
  }

  public setActiveToken(tno) {
    this.SkippedTokenEvent.emit(tno);
    this.isShow = false;
    this.isActiveClicked = true;
  }
  
}
