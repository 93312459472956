<header class="header">
  <ul class="topnav">
    <li class="flex-logo-div" style="float:left"><h1>FLEXLINK</h1></li>
    <li style="float:right"><a class="active">Welcome User!</a></li>
  </ul>
  <script>
    function myFunction() {
      var d = new Date();
      d.setDate(15);
      document.getElementById("appointmentstartdate").innerHTML = d;
    }
  </script>
</header>
<div>
  <br />
</div>
<div class="btn-toolbar" style="justify-content:flex-end; display: flex;" role="group">
  <br />
  <br />
</div>

<div class="controls" style="margin-top:0px">
  <div class="col-md-12">
    <div class="col-md-12">
      <div class="col-md-3">
        <div class="card mt-5 myForm col-md-12">
          <label style="font-size:14px;float:right;"> {{ showStoreName }}</label>
          <br />
          <br />
          <label for="appointmentstartdate" style="font-size:14px"><span style="color:red;">*</span> Appointment From Date :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <input id="appointmentstartdate" name="appointmentstartdate" [(ngModel)]="appointmentstartdate" type="date" class="controls" style="color:black;" />
          <br />
          <label for="appointmentenddate" style="font-size:14px"><span style="color:red;">*</span> Appointment End Date :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
          <input id="appointmentenddate" name="appointmentenddate" [(ngModel)]="appointmentenddate" type="date" class="controls" style="color:black;" />
          <br />
          <button type="button" class="btn btn-info btn-margin" style="text-align:center; font-size:15px; border-radius:10px" (click)="ShowAppointments()">
            Show Appointments
          </button>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row" style="margin-right:2px; margin-top:3px">
          <div class="card mt-5 myForm col-md-12">
            <div class="controls" style="margin-top:0px">
              <div style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; text-align: center;color: white;font-size: 20px;">
                List of Open Tokens
              </div>
              <div class="col-md-12">
                <table class="table" align="center">
                  <thead>
                    <tr style="font-size:13px;">
                      <th><input type="checkbox" [(ngModel)]="isMasterSel" name="list_name" value="h1" (change)="checkUncheckAll()" />  <strong>Select All</strong> </th>
                      <th><strong>Customer Name</strong></th>
                      <th><strong>Brand Name</strong></th>
                      <th><strong>Token No</strong></th>
                      <th><strong>Mobile No</strong></th>
                      <th><strong>Appointment Date</strong></th>
                      <th><strong>Appointment Slot</strong></th>
                      <th><strong>Status</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor='let cancelappointment of dataList | paginate: config' >
                      <td> <input type="checkbox" class="chckbx" id="tkn_id" [(ngModel)]="cancelappointment.isSelected" /> </td>
                      <td>{{ cancelappointment.customerName }}</td>
                      <td>{{ cancelappointment.brandName }}</td>
                      <td>{{ cancelappointment.tokenNo }}</td>
                      <td>{{ cancelappointment.mobileNo }}</td>
                      <td>{{ cancelappointment.appointmtDate }}</td>
                      <td>{{ cancelappointment.appointmtTime }}</td>
                      <td>{{ cancelappointment.statusName }}</td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <button type="button" class="btn btn-info btn-margin" style="text-align:left; font-size:15px; border-radius:10px" (click)="CanceTokens()" *ngIf="showConfirmButton">
                          Cancel Appointment(s)
                        </button>&nbsp;&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <pagination-controls (pageChange)="pageChanged($event)" class="table-responsive"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

