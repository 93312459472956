import { BaseModel } from './base-model';

export class ProcessFields extends BaseModel {
  public EntityID: number;
  public ChildEntityID: number;
  public EntityKey: string;
  public ChildEntityKey: string;
  public ChildEntityKey1: string;
  public UserEmail: string;
  public RequestToken: string;
  public ApplicationCode: string;
  public ApiUrl: string;
  public CustomerName: string;
  public StoreID: number;
  public CounterID: number;
  public TokenNo: string;
  public CategoryName: string;
  public LocalStoreID: number; // to get states & cities for appointment page
  public LanguageID: number; // to get states & cities for appointment page
  public UserID: number; // to get states & cities for appointment page
  public AppointmtDateTime: string;
  public IsMasterCP: number;
}

export class SMSProcessFields extends BaseModel {
  public mobileNo: string;
  public tokenNo: string;
  public CustomerName: string;
  public brandName: string;
  public appointmtDate: string;
  public appointmtTime: string;
  public AppointmtDateTime: string;
  public StoreID: number;
  public IsMasterCP: number;
}
