import { Injectable }  from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';





@Injectable({
  providedIn: 'root'
})
export class FileUploadService  {

  constructor(private dataService: DataService, private http: HttpClient) { }

  //public insertFileUpload(selectedEntity): Observable<string> {
  //  return this.dataService.add<string>("fileupload/save", selectedEntity);
  

  uploadfile(HolidayLoaderModel: any) {
    debugger;
    let input = new Object();
   //input.append("file", HolidayLoaderModel);
    input = HolidayLoaderModel;
    return this.http.post("http://localhost:64411/HolidayLoader/saveupload", HolidayLoaderModel)
      .subscribe((res) => console.log(res),
    )
    //this.dataService.add<any>("HolidayLoader/saveupload", input).subscribe(data => {
    //  console.log(data);
    //})
       //.map(res => res.json());
  }


  
}






