import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerComponent } from './component/customer/customer.component';
import { ReceptionistComponent } from './component/receptionist/receptionist.component';
import { Configuration } from './shared/roots/configuration.component';
import { DataService, CustomInterceptor } from './shared/services/data.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { UserInfoComponent } from './component/userinfo/userinfo.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { NumberToWordsPipe } from './shared/services/number-to-words.pipe';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { ModalNotificationComponent } from './shared/components/modal-notification/modal-notification.component';
import { FilterPipe } from './shared/services/filter.pipe';
import { UtilityService } from './shared/services/utility.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AuthenticationComponent } from './component/authentication.component';
import { DisplayTokenComponent } from './component/display-token/display-token.component';
import { GlobalService } from '../app/shared/services/global.service';
import { ErrorpageComponent } from './component/errorpage/errorpage.component';
import { BroadcastService } from './shared/services/broadcast.service';
import { SkippedtokenComponent } from './component/skippedtoken/skippedtoken.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReleaseCountersComponent } from './component/release-counters/release-counters.component';
import { AppointmentComponent } from './component/appointment/appointment.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HolidayLoaderComponent } from './component/holiday-loader/holiday-loader.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FileimportComponent } from './component/fileimport/fileimport.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CancelappointmentComponent } from './component/cancelappointment/cancelappointment.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

export function initApp(configuration: Configuration) {
  return async () => {
    await configuration.readAppSettings();
  }
}

export function configurationFactory(provider: Configuration) {
  return () => {};
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/translation/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CustomerComponent,
    ReceptionistComponent,
    UserInfoComponent,
    NotificationComponent,
    LoadingComponent,
    ModalNotificationComponent,
    NumberToWordsPipe,
    FilterPipe,
    AuthenticationComponent,
    DisplayTokenComponent,
    ErrorpageComponent,
    SkippedtokenComponent,
    ReleaseCountersComponent,
    AppointmentComponent,
    HolidayLoaderComponent,
    FileimportComponent,
    CancelappointmentComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    DlDateTimeDateModule,  // <--- Determines the data type of the model
    DlDateTimePickerModule,
    SchedulerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SchedulerModule,
    BrowserAnimationsModule,
    FileUploadModule,
    //GridModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange', logEnabled: true }),
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    HttpModule
  ],
  providers: [Configuration, DataService, NgxSpinnerModule, UtilityService, GlobalService, BroadcastService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true }, DatePipe, NumberToWordsPipe, FilterPipe,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [Configuration], multi: true },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
