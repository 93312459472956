import {  Component, OnInit, ViewChild, Inject,  LOCALE_ID,  HostListener, TemplateRef, } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { TokenCount } from '../../shared/components/QMSToken/TokenCount';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProcessFields } from '../../shared/models/processfields-model';
import { QMSTokenModel } from '../../shared/components/QMSToken/QMSToken';
import { QMSAppointmentModel } from '../../shared/components/QMSToken/QMSAppointment';
import { OEM } from '../../shared/components/QMSToken/OEM';
import { Injectable } from '@angular/core';
import { UtilityService } from '../../shared/services/utility.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Configuration } from 'src/app/shared/roots/configuration.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../../shared/services/global.service';
import { States } from 'src/app/shared/components/QMSToken/States';
import { Cities } from 'src/app/shared/components/QMSToken/Cities';
import { Category } from '../customer/Category';
import { Brands } from 'src/app/shared/models/brand-model';
import { Asp } from 'src/app/shared/components/QMSToken/Asp';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { endOfDay, addMonths, addWeeks, addDays } from 'date-fns';
import { DAYS_IN_WEEK, SchedulerViewDay, SchedulerViewHour, SchedulerViewHourSegment, CalendarSchedulerEvent, CalendarSchedulerEventAction, startOfPeriod,
  endOfPeriod, addPeriod, subPeriod, SchedulerDateFormatter, SchedulerEventTimesChangedEvent, CalendarSchedulerEventStatus } from 'angular-calendar-scheduler';
import { CalendarView,  CalendarDateFormatter,  DateAdapter,} from 'angular-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentTime } from './appointment-model';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: SchedulerDateFormatter,
    },
  ],
})

export class AppointmentComponent implements OnInit {
  constructor(private dataService: DataService,
    private aRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private configuration: Configuration,
    private spinner: NgxSpinnerService,
    private _globalService: GlobalService,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) locale: string,
    private modalService: NgbModal,
    private dateAdapter: DateAdapter /*used for get appointment api call*/
  ) {
    this._globalService.data$.subscribe(data => {

    }, error => {
      console.log('Error: ' + error);
    });

    this.locale = locale;
    this.adjustViewDays();
    this.dateOrViewChanged();
  }

  @ViewChild('customerForm') public Form: NgForm;
  public states: States[];
  public selectedStateName: any = "";
  public categories: Category[];
  public selectedCategoryID: any = "";
  public brands;
  public TotalTokenCount: number = 0;
  public CurrentToken: string;
  public cities;
  public asp: Asp[];
  public selectedaspID: any = "";
  public QMSToken = {} as QMSTokenModel;
  public selectedCityName: any = "";
  public timeZone: string;
  public applicationCode: string;
  public storeID: any;
  public apiUrl: string;
  public cityStatus: boolean = false;
  public aspStatus: boolean = false;
  public categoryStatus: boolean = false;
  public stateStatus: boolean = false;
  public customerStatus: boolean = false;
  public mobileStatus: boolean = false;
  public selectedDate;
  public selectedBrandID;
  public selectedBrandName;
  public showCalendar: boolean = false;
  public formGroup: UntypedFormGroup;
  public showConfirmButton: boolean = false;
  public showCancelButton: boolean = false;
  item: number;
  public minDate: Date = new Date();
  public maxDate: Date = new Date();
  public storeIDs;
  public brandHash: any;
  public otp: any;
  public result: any;
  public disableBrands: boolean = false;
  public isbrandHash: boolean = false;
  public isMobileText: boolean = false;
  public validateOtp: boolean = false;
  public generateOtp: boolean = false;
  public validateOtpDisable: boolean = false;
  public generateOtpDisable: boolean = false;
  public mobileText: boolean = false;
  public showAppointmentSlots: boolean = false;
  public holiday: any;
  public getNonOperationalHours: any;
  public selectedCategoryName: any;
  public selectedBrandLogoPaths: string = "../../../assets/Images/No-Logo.png";

  /*Calendar Scheduler View Variable Starts*/
  title = 'Appointment Calendar Scheduler';
  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = DAYS_IN_WEEK;
  refresh: Subject<any> = new Subject();
  locale: string = 'in';
  hourSegments: number = 4;
  weekStartsOn: number = 1;
  startsWithToday: boolean = true;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = []; // [0];
  dayStartHour: number = 11;
  dayEndHour: number = 19;
  minimumDate: Date = new Date();
  maximumDate: Date = endOfDay(addDays(new Date(), 7));
  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  selectedAppointmentTime = {} as AppointmentTime;
  savedAppointmentTime = {} as AppointmentTime;
  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label:
        '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: 'Delete',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log("Pressed action 'Delete' on event " + event.id);
      },
    },
    {
      when: 'cancelled',
      label:
        '<span class="valign-center"><i class="material-icons md-18 md-red-500">autorenew</i></span>',
      title: 'Restore',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log("Pressed action 'Restore' on event " + event.id);
      },
    },
  ];
  @ViewChild('timeSelectionModal') timeSelectionModal: TemplateRef<any>;
  eventsDetails: CalendarSchedulerEvent[];
  event1: CalendarSchedulerEvent[];
  /*Calendar Scheduler View Variable Ends*/

  ngOnInit() {
    this.brandHash = this.aRoute.snapshot.params["hash"];
    this.applicationCode = sessionStorage.getItem('applicationCode');
    if (this.brandHash == null) {
      this.dataService.getAuthKey().subscribe(data => {
        sessionStorage.setItem('requestToken', data.toString());
      })
    }
    else {
      this.dataService.getAuthKeyAppointment().subscribe(data => {
        sessionStorage.setItem('requestToken', data.toString());
        this.applicationCode = 'INDLENOVOUAT';
      })
    }
    this.getBrands();
  }

  // to get states for appointment page
  public getStates() {
    this.selectedStateName = "";
    this.selectedCityName = "";
    this.selectedaspID = "";
    this.selectedCategoryID = "";
    this.showCalendar = false;
    var processFields = new ProcessFields();
    processFields.EntityKey = this.selectedBrandName;

    this.dataService.add<any>('QMSToken/getLogoByBrand', processFields).subscribe(data => {
      this.selectedBrandLogoPaths = data;
       
    }, function () {
      this.utilityService.showFailMessage.fire('Error!. Please try again.');
    }
    );

    this.dataService.add<any>('QMSToken/getStatesByBrand', processFields).subscribe(data => {
      this.states = data;
      this.stateStatus = true;
    }, function () {
      this.utilityService.showFailMessage.fire('Error!. Please try again.');
    }
    );
  }

  // to get cities for appointment page
  public getCities() {
    this.selectedCityName = "";
    this.selectedaspID = "";
    this.selectedCategoryID = "";
    this.showCalendar = false;
    var processFields = new ProcessFields();
    processFields.ChildEntityKey = this.selectedBrandName;
    processFields.EntityKey = this.selectedStateName;
      this.dataService.add<any>('QMSToken/getCitiesByBrand', processFields).subscribe(data => {
      this.cities = data;
      this.cityStatus = true;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  // to get ASP for appointment page
  public getAsp() {
    this.selectedaspID = "";
    this.selectedCategoryID = "";
    this.showCalendar = false;
    var processFields = new ProcessFields();
    processFields.ChildEntityKey = this.selectedBrandName;
    processFields.EntityKey = this.selectedCityName;
    this.dataService.add<string>('QMSToken/getStoresByBrands', processFields).subscribe(data => {
      this.storeIDs = data;
      this.getStoreIDs();
    });
  }
  // to get multiple store id's  from store names after selecting state & city
  public getStoreIDs() {
    var processFields = new ProcessFields();
    processFields.ChildEntityKey = this.storeIDs;
    processFields.EntityKey = this.selectedCityName;
    if (this.brandHash == null) {
      this.dataService.addExternal<Asp[]>('unitRegistration/getlocalstorebycity', processFields).subscribe(data => {
        this.asp = data;
        this.aspStatus = true;
      }, function () {
        this.utilityService.showFailMessage.fire('Error!. Please try again.');
      }
      );
    }
    else {
      this.dataService.addFlexLink<Asp[]>('unitRegistration/getlocalstorebycity', processFields).subscribe(data => {
        this.asp = data;
        this.aspStatus = true;
      }, function () {
        this.utilityService.showFailMessage.fire('Error!. Please try again.');
      }
      );
    }
  }
  // to get all categories after selecting store
  public getCategories() {
    this.selectedCategoryID = "";
    this.showCalendar = false;
    var processFields = new ProcessFields();
    processFields.EntityID = 0;
    processFields.ChildEntityKey = this.selectedBrandName;
    this.dataService.add<Category[]>('category/getCategory', processFields).subscribe(data => {
      this.categories = data;
      this.categoryStatus = true;
    }, function () {
      this.utilityService.showFailMessage.fire('Error!. Please try again.');
    }
    );
  }

  // to get all brands on init 
  public getBrands() {
    this.selectedBrandID = "";
    this.selectedBrandName = "";
    this.selectedStateName = "";
    this.selectedCityName = "";
    this.selectedaspID = "";
    this.selectedCategoryID = "";
    this.showCalendar = false;
    var processFields = new ProcessFields();
    processFields.EntityKey = this.brandHash;
    this.dataService.add<Brands[]>('QMSToken/getallBrands', processFields).subscribe(data => {
      this.brands = data;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
    if (this.brandHash != null) {
      this.isbrandHash = true;
      this.disableBrands = true;
      this.stateStatus = true;
      this.dataService.add<Brands[]>('QMSToken/getallBrands', processFields).subscribe(data => {
        this.brands = data;
        this.selectedBrandName = this.brands[0].brandName;
        this.getStates();
      }, function () {
        this.utilityService.showFailMessage('Error!. Please try again.');
      }
      );
    }
  }

  public getOTP() {
    var processFields = new ProcessFields();
    processFields.EntityKey = this.QMSToken.MobileNo;
    processFields.ChildEntityKey = this.QMSToken.CustomerName;
    processFields.EntityID = this.getRandomNumberBetween(100000, 999999);
    this.otp = processFields.EntityID;
    this.dataService.add<any>('QMSToken/otpDetails', processFields).subscribe(data => {
      this.utilityService.showSaveSuccessMessage('OTP Sent Successfully');
      this.validateOtp = true;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
    this.dataService.addFlexLink<any>('smsconfiguration/insertOtpLog', processFields).subscribe(data => {});
  }

  public getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public validateOTP() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.QMSToken.Otp;
    processFields.ChildEntityID = this.otp;
    processFields.ChildEntityKey = this.QMSToken.MobileNo;
    this.dataService.add<any>('QMSToken/otpValidation', processFields).subscribe(data => {
      if (data.toString().indexOf("Success") >= 0) {
        this.utilityService.showSaveSuccessMessage('OTP Validated Successfully');
        this.showAppointmentSlots = true;
        this.generateOtpDisable = true;
        this.validateOtpDisable = true;
        this.mobileText = true;
        this.validateOtp = false;
      }
      else {
        this.utilityService.showFailMessage('Invalid OTP. Please enter valid OTP.');
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  // to save token after entering all customer details
  public SaveToken() {
    this.QMSToken.CategoryID = this.selectedCategoryID;
    this.QMSToken.BrandName = this.selectedBrandName;
    this.QMSToken.StatusCode = "OPEN";
    this.QMSToken.TimeZone = this.timeZone;
    this.QMSToken.StoreID = this.selectedaspID;
    this.QMSToken.CityName = this.selectedCityName;
    this.QMSToken.ApplicationCode = this.applicationCode;
    for (var i in this.brands) {
      if (this.brands[i].brandName == this.selectedBrandName) {
        this.QMSToken.brandName = this.brands[i].brandName;
      }
    }
    this.QMSToken.AppointmtDateTime = this.savedAppointmentTime.start.toString();
    //this.QMSToken.Title = this.formGroup.value.title.toString();
    this.QMSToken.Title = this.selectedBrandName + ' '+ '-' + ' ' + this.selectedCategoryName;
    this.dataService.add<any>('QMSToken/saveAppointmentToken', this.QMSToken).subscribe(data => {
      if (data.toString().indexOf("Success") >= 0) {
        let Token = data.toString().split("|");
        let TokenNumber = Token[1];
        this.QMSToken.TokenNo = TokenNumber;
        this.utilityService.showAppointmentSuccessMessage("Your Token Number is " + TokenNumber);
        this.SMSTemplate();      // function called to make api call for FL common & enter all details in sms_log table
        this.Form.reset();
        this.QMSToken = {} as QMSTokenModel;
        this.selectedStateName = "";
        this.selectedCityName = "";
        this.showCalendar = false;
        this.cityStatus = false;
        this.aspStatus = false;
        this.categoryStatus = false;
        this.stateStatus = false;
        this.customerStatus = false;
        this.mobileStatus = false;
        this.showConfirmButton = false;
        this.showCancelButton = false;
        this.generateOtp = false;
        this.showAppointmentSlots = false;
        this.selectedBrandID = "";
        this.selectedBrandName = "";
        this.mobileText = false;
        this.generateOtpDisable = false;
        this.validateOtpDisable = false;
        this.getBrands();
        this.selectedAppointmentTime = {} as AppointmentTime;
        this.savedAppointmentTime = {} as AppointmentTime;
      }
      else {
        this.utilityService.showFailMessage('Only two bookings per slot allowed.');
        this.getAppointments();
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }
  // function called to make api call for FL common & enter all details in sms_log table
  public SMSTemplate() {
    var processFields = new ProcessFields();
    processFields.ChildEntityKey = this.selectedBrandName;
    processFields.EntityKey = this.QMSToken.MobileNo;
    processFields.CustomerName = this.QMSToken.CustomerName;
    processFields.AppointmtDateTime = this.savedAppointmentTime.start.toString();
    processFields.ChildEntityKey1 = this.QMSToken.TokenNo;
    processFields.EntityID = this.selectedaspID;
    if (this.brandHash == null) {
      this.dataService.addExternal<any>('smsconfiguration/insertSmsLog', processFields).subscribe(data => { },
        function () {
          this.utilityService.showFailMessage.fire('Error!. Please try again.');
        }
      );
    }
    else {
      this.dataService.addFlexLink<any>('smsconfiguration/insertSmsLog', processFields).subscribe(data => { },
        function () {
          this.utilityService.showFailMessage.fire('Error!. Please try again.');
        }
      );
    }
  }
  // to get booked appointment slots store wise on UI
  public getAppointments() {
    var $context = this;
    this.showCalendar = true;
    this.showConfirmButton = true;
    this.showCancelButton = true;
    var processFields = new ProcessFields();
    processFields.EntityID = this.selectedaspID;
    processFields.ChildEntityKey = this.selectedCityName;
    processFields.EntityKey = this.selectedBrandName;
    processFields.ChildEntityID = this.selectedCategoryID;
    this.getSchedule(processFields);

    //this.selectedCategoryName = (<HTMLSelectElement>document.getElementById('form_categoryname')).value;
    this.dataService.add<any>('category/getCategoryName', processFields).subscribe(data => {
      this.selectedCategoryName = data;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
    this.dataService.add<any>('QMSToken/getHolidayCalendar', processFields).subscribe(data => {
      this.holiday = data;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
    this.dataService.add<any>('QMSToken/getNonOperationalHours', processFields).subscribe(data => {
      this.getNonOperationalHours = data;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }
  getSchedule(processFields) {

    this.dataService.add<any>('QMSToken/getAppointmentSlots', processFields).subscribe(data => {
      this.eventsDetails = data.map((item: any) =>
        <CalendarSchedulerEvent>{
          id: item.id.toString(), start: new Date(item.start), end: new Date(item.end), title: item.title, actions: this.actions,
          color: { primary: '#E0E0E0', secondary: '#EEEEEE' }, content: item.title, status: 'ok' as CalendarSchedulerEventStatus
        }
      );
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    });
  }
  // show/hide fields
  public showFields() {
    this.showCalendar = false;
    this.customerStatus = true;
    this.mobileStatus = true;
    this.generateOtp = true;
    if (this.brandHash == null) {
      this.generateOtp = false;
      this.showAppointmentSlots = true;
      this.mobileStatus = true;
      this.isMobileText = true;
    }
  }
  // reset Fields after clicking cancel appointment button from UI
  public resetFields() {
    this.utilityService.showConfirmationMessage('Are your sure you want to Cancel this appointment?').then((result) => {
      this.Form.reset();
      this.QMSToken = {} as QMSTokenModel;
      this.selectedStateName = "";
      this.selectedCityName = "";
      this.showCalendar = false;
      this.cityStatus = false;
      this.aspStatus = false;
      this.categoryStatus = false;
      this.stateStatus = false;
      this.customerStatus = false;
      this.mobileStatus = false;
      this.showConfirmButton = false;
      this.showCancelButton = false;
      this.generateOtp = false;
      this.showAppointmentSlots = false;
      this.selectedBrandID = "";
      this.selectedBrandName = "";
      this.mobileText = false;
      this.generateOtpDisable = false;
      this.validateOtpDisable = false;
      this.getBrands();
    });
  }

  // validation to book appointment only after 2 hours from current system time
  public onAdd(args: any): void {
    var date = new Date();
    date.setHours(date.getHours() + 2);

    if (args.dataItem.start <= date) {
      this.utilityService.showFailMessage('Please select time after 2 hours from now!');
      args.preventDefault();
    }
    //validation to block appointment booking for Holidays from holiday api
    for (let i in this.holiday) {
      if (args.dataItem.start.toLocaleDateString() == this.holiday[i].holidayDate) {
        this.utilityService.showFailMessage('Appointment cannot be booked on Holidays!');
        args.preventDefault();
      }
    }
    for (let h in this.getNonOperationalHours) {
      if (args.dataItem.start.toLocaleTimeString() >= this.getNonOperationalHours[h].fromTime && args.dataItem.start.toLocaleTimeString() <= this.getNonOperationalHours[h].toTime && this.selectedaspID == this.getNonOperationalHours[h].storeID) {
        this.utilityService.showFailMessage('Appointment cannot be booked during non-operational hours');
        args.preventDefault();
      }
    }
    if (this.preventSlot(args.dataItem)) {
      alert('Fail');
      args.preventDefault();
    }
  }

  //this.dataService.getAuthKey().subscribe(data => {
  private preventSlot(args: any): boolean {
    let i = false;
    let timeslotCount = 0;
    return i;
  }
  private occupiedSlot(args: any, result): boolean {
    let occupied = false;
 
    let timeslotCount = 0;
    var $context = this;
    return occupied;
  }
 // validation to disable DRAG existing event
  public onDragStart(args: any): void {
    args.preventDefault();
  }
  public onDrag(args: any): void {
    if (this.occupiedSlot(args, true)) {
      args.setHintClass('invalid');
    }
  }
  public onDragEnd(args: any): void {
    if (this.occupiedSlot(args, true)) {
      args.preventDefault();
    }
  }
 // validation to disable DELETE existing event
  public onRemove(args: any): void {
    args.preventDefault();
  }
 // validation to disable EDIT existing event
  public onEdit(args: any): void {
    args.preventDefault();
  }
 // validation to disable RESIZE existing event
  public onResizeStart(args: any): void {
    args.preventDefault();
  }
  public onResizeFn(args: any): void {
    if (this.occupiedSlot(args, true)) {
      args.setHintClass('invalid');
    }
  }
  public onResizeEnd(args: any): void {
    if (this.occupiedSlot(args, true)) {
      args.preventDefault();
    }
  }

  public startEndValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const start = fg.get('start').value;
    const end = fg.get('end').value;

    if (start !== null && end !== null && start.getTime() < end.getTime()) {
      return null;
    } else {
      return { range: 'End date must be greater than Start date' };
    }
  }

  /*Calendar Scheduler Method Starts*/
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustViewDays();
  }

  adjustViewDays(): void {
    const currentWidth: number = window.innerWidth;
    if (currentWidth <= 450) {
      this.viewDays = 1;
    } else if (currentWidth <= 768) {
      this.viewDays = 3;
    } else {
      this.viewDays = DAYS_IN_WEEK;
    }
  }

  changeDate(date: Date): void {
    console.log('changeDate', date);
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarView): void {
    console.log('changeView', view);
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.startsWithToday) {
      this.prevBtnDisabled = !this.isDateValid(
        subPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          this.viewDate,
          1
        )
      );
      this.nextBtnDisabled = !this.isDateValid(
        addPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          this.viewDate,
          1
        )
      );
    } else {
      this.prevBtnDisabled = !this.isDateValid(
        endOfPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          subPeriod(
            this.dateAdapter,
            CalendarView.Day /*this.view*/,
            this.viewDate,
            1
          )
        )
      );
      this.nextBtnDisabled = !this.isDateValid(
        startOfPeriod(
          this.dateAdapter,
          CalendarView.Day /*this.view*/,
          addPeriod(
            this.dateAdapter,
            CalendarView.Day /*this.view*/,
            this.viewDate,
            1
          )
        )
      );
    }

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  private isDateValid(date: Date): boolean {
    return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {
    console.log('dayHeaderClicked Day', day);
  }

  hourClicked(hour: SchedulerViewHour, segment: SchedulerViewHourSegment, event: any): void {
    console.log('hourClicked Hour', hour);
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
    this.selectedAppointmentTime = {} as AppointmentTime;
    /*Validation Starts*/
    var date = new Date();
    date.setHours(date.getHours() + 2);

    if (segment.date <= date) {
      this.utilityService.showFailMessage('Please select time after 2 hours from now!');
      return;
    }
    for (let i in this.holiday) {
      if (segment.date.toLocaleDateString() == this.holiday[i].holidayDate) {
        this.utilityService.showFailMessage('Appointment cannot be booked on Holidays!');
        return;
      }
    }
    for (let h in this.getNonOperationalHours) {
      if (segment.date.toLocaleTimeString() >= this.getNonOperationalHours[h].fromTime && segment.date.toLocaleTimeString() <= this.getNonOperationalHours[h].toTime && this.selectedaspID == this.getNonOperationalHours[h].storeID) {
        this.utilityService.showFailMessage('Appointment cannot be booked during non-operational hours');
        return;
      }
    }
    if (this.preventDateSlot(segment)) {
      alert('Fail');
      return;
    }
    /*Validation Ends*/
    this.selectedAppointmentTime.title = this.selectedBrandName + ' ' + '-' + ' ' + this.selectedCategoryName;
    this.selectedAppointmentTime.start = segment.date;
    this.selectedAppointmentTime.end = new Date(segment.date.getTime() + 15 * 60000);
    this.selectedAppointmentTime.startTime = this.selectedAppointmentTime.start.toTimeString().substring(0, 8);
    this.selectedAppointmentTime.endTime = this.selectedAppointmentTime.end.toTimeString().substring(0, 8);
    this.modalService.open(this.timeSelectionModal, { backdrop: 'static' });
  }

  eventClicked(action: string, event: CalendarSchedulerEvent): void {
    console.log('eventClicked Action', action);
    console.log('eventClicked Event', event);
  }

  eventTimesChanged({event, newStart, newEnd, type,}: SchedulerEventTimesChangedEvent): void {
    console.log('eventTimesChanged Type', type);
    console.log('eventTimesChanged Event', event);
    console.log('eventTimesChanged New Times', newStart, newEnd);
    //this.eventsDetails.subscribe(events => {
    const ev: CalendarSchedulerEvent = this.eventsDetails.find((e) => e.id === event.id);
      ev.start = newStart;
      ev.end = newEnd;
    //});
    this.refresh.next();
  }

  closeModal() {
    this.selectedAppointmentTime = {} as AppointmentTime;
    this.modalService.dismissAll();
  }

  private preventDateSlot(segment: any): boolean {
    let i = false;
    let timeslotCount = 0;
    this.eventsDetails.forEach((item:any) => {
      if (item.start.toString() == segment.date.toString()) {
        timeslotCount++;
      }
    });
    if (timeslotCount == 2) {
      i = true;
      //alert(timeslotCount);
      return true;
    }
    return i;
  }

  addSlotinCalendar() {
    this.savedAppointmentTime = {} as AppointmentTime;
    this.savedAppointmentTime = this.selectedAppointmentTime;
    this.eventsDetails.push({
      id: '0',
      start: new Date(this.savedAppointmentTime.start), end: new Date(this.savedAppointmentTime.end), title: this.savedAppointmentTime.title, actions: this.actions,
      color: { primary: '#E0E0E0', secondary: '#EEEEEE' }, content: this.savedAppointmentTime.title, status: 'ok' as CalendarSchedulerEventStatus,
    });
    this.refresh.next();
    this.modalService.dismissAll();
  }
  /*Calendar Scheduler Method Ends*/
}
