<header class="header">
  <ul class="topnav">
    <li class="flex-logo-div" style="float:left"><h1>FLEXLINK</h1></li>
    <li style="float:right"><a class="active">Welcome User!</a></li>
  </ul>
</header>
<div class="row">
  <div class="col-lg-3 col-md-3 col-sm-3">
  </div>
  <div class="col-lg-6 col-md-6 col-sm-6 divSpacing">
    <div class="form-group">
      <label for="form_storename">Store Name</label>
      <select class="form-control col-lg-2 col-md-2 col-sm-2" (change)="onStoreChange($event.target.value)"
              [(ngModel)]="selectedStoreID" name="StoreName">
        <option value="">--Select Store Name--</option>
        <option *ngFor="let item of allStores" [value]="item.storeID">{{item.storeName}}</option>
      </select>
    </div><br /><br />

    <div class="row">
      <div class="form-group col-sm-6">
        <label for="form_counterno">Counter Number</label>
        <select class="form-control" (change)="onCounterChange($event.target.value)" name="CounterNo"
                [(ngModel)]="selectedCounterID" [disabled]="!isDropdownVisible">
          <option value="">--Select Counter No--</option>
          <option *ngFor="let item of allCounters" [value]="item.counterID">{{item.counterNo}}</option>
        </select>
      </div>
    </div>
</div>
</div>
