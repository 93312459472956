import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection } from '@aspnet/signalr'
import * as signalR from '@aspnet/signalr';
import { Configuration } from '../roots/configuration.component';
@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private hubConnection: HubConnection | undefined;
  public Notification: EventEmitter<string> = new EventEmitter<string>();
  public TotalToken: EventEmitter<string> = new EventEmitter<string>();
  public GetNextTokenValue: EventEmitter<string> = new EventEmitter<string>();
  public GetNextCounterValue: EventEmitter<string> = new EventEmitter<string>();
  public GetSkippedTokenValue: EventEmitter<string> = new EventEmitter<string>();
  public GetUpdateCounterValue: EventEmitter<string> = new EventEmitter<string>();
  constructor(private configuration: Configuration) {
  }

  public initialize(): void {
    this.stopConnection();

    //http://localhost:64411/hubs/notification
    //https://queuemanagementservice.azurewebsites.net/api/hubs/notification
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.configuration.server + "hubs/notification", {
      accessTokenFactory: () => {
        return '';
      }
    }).configureLogging(signalR.LogLevel.Information).build();

    this.hubConnection.on("Notification", (data: any) => {
      this.Notification.emit(data);
    });
    this.hubConnection.on("TotalToken", (data: any) => {
      this.TotalToken.emit(data);
    });
    this.hubConnection.on("GetNextToken", (data: any) => {
      this.GetNextTokenValue.emit(data);
    });
    this.hubConnection.on("GetNextCounter", (data: any) => {
      this.GetNextCounterValue.emit(data);
    });
    this.hubConnection.on("GetSkippedToken", (data: any) => {
      this.GetSkippedTokenValue.emit(data);
    });
    this.hubConnection.on("UpdateCounter", (data: any) => {
      this.GetUpdateCounterValue.emit(data);
    });
    this.hubConnection.start().then((data: any) => {
      console.log('Now connected');
    }).catch((error: any) => {
      console.log('Could not connect ' + error);
      setTimeout(() => this.initialize(), 3000);
      });    
  }

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop();
      this.hubConnection = null;
    }
  };

}
