<header class="header">
  <ul class="topnav">
    <li class="flex-logo-div" style="float:left"><h1>FLEXLINK</h1></li>
    <li style="float:right"><a class="active">Welcome User!</a></li>
  </ul>
</header>
<form id="contact-form" method="post" action="contact.php" role="form">
  <div class="controls">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3">
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <div class="form-group">
            <label for="form_currentToken">Total Pending Tokens <span class="tokenBadge">{{TotalTokenCount}}</span></label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 2%;margin-bottom:20px;">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 divSpacing">
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="form_counterno">Counter Number</label>
              <select class="form-control" (change)="onCounterChange($event.target.value)" name="CounterNo"
                      [(ngModel)]="selectedCounterID" [disabled]="!isDropdownVisible">
                <option value="">--Select Counter No--</option>
                <option *ngFor="let item of allCounters" [value]="item.counterID">{{item.counterNo}}</option>
              </select>
            </div>
            <div class="form-group col-sm-6">
              <button type="button" style="margin-top:24px" class="btn btn-info" *ngIf="Showbutton" (click)="onCounterclose()">
               Release Counter
              </button>
            </div>
          </div>
          <div class="form-group">
            <label for="form_categoryname">Category</label>
            <select class="form-control col-lg-2 col-md-2 col-sm-2" name="categoryname" id="form_categoryname"
                    [(ngModel)]="selectedCategoryID" (change)="selectCategoryChangeHandler($event)">
              <option value="">--All--</option>
              <option *ngFor="let cat of categories" [value]="cat.categoryID">{{cat.categoryName}}</option>
            </select>
          </div>
        </div>
          <div class="col-lg-3 col-md-3 col-sm-3">
          </div>
        </div>
      <div class="row" *ngIf="ShowLabels">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group">
            <label for="form_currentToken">Current Token No.</label><br />
            <label [ngClass]="{'lblclosed':isClosed == 1,'info':isClosed == 0}" class="labelCustom" for="form_currentToken">{{QMSToken.tokenNo}}</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row" *ngIf="ShowLabels">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group">
            <label for="form_categoryname">Category Name</label><br />
            <label [ngClass]="{'lblclosed':isClosed == 1,'info':isClosed == 0}" class="labelCustom" for="form_categoryname">{{QMSToken.categoryName}}</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row" *ngIf="ShowLabels">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group">
            <label for="form_brandname">Brand Name</label><br />
            <label [ngClass]="{'lblclosed':isClosed == 1,'info':isClosed == 0}" class="labelCustom" for="form_brandname">{{QMSToken.brandName}}</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row" *ngIf="ShowLabels">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group">
            <label for="form_customername">Customer Name</label><br />
            <label [ngClass]="{'lblclosed':isClosed == 1,'info':isClosed == 0}" class="labelCustom" for="form_customername">{{QMSToken.customerName}}</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row" *ngIf="ShowLabels">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group">
            <label for="form_mobileNumber">Mobile Number</label><br />
            <label [ngClass]="{'lblclosed':isClosed == 1,'info':isClosed == 0}" class="labelCustom" for="form_mobileNumber">{{QMSToken.mobileNo}}</label>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-9">
        <div class="row" style="text-align:right;">
          <label for="form_pauseCheckbox" style="padding-right: 3em;">
            <input type="checkbox" name="pauseCheckbox" [(ngModel)]="pauseNextToken" /> Pause Next Token
          </label>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="row" style="text-align:center;">

          <!--<div class="custom-control custom-checkbox" style="text-align:center;">
          <input type="checkbox" class="custom-control-input" id="pauseCheckbox" [(ngModel)]="pauseNextToken">
          <label class="custom-control-label" for="pauseCheckbox">Pause Next Token</label>
        </div>-->
          <div class="btn-toolbar" style="justify-content: center; display: flex;" role="group">
            <button type="button" class="btn btn-info btn-margin" style="text-align:left;" [disabled]="isGetOperationVisible && isActiveClicked" (click)="GetNextToken()">
              Get Next Token
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-info btn-margin" (click)="SkipToken()" [disabled]="isUpdateOperationVisible">
              Skip Token
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-info btn-margin" (click)="getSkippedTokens()" [disabled]="isGetOperationVisible && isActiveClicked">
              Get Skipped Tokens
            </button>&nbsp;&nbsp;
            <button type="button" class="btn btn-info btn-margin" (click)="CloseToken()" [disabled]="isUpdateOperationVisible">
              Close Token
            </button>
          </div>
          <!--<button type="button" class="btn btn-info btn-margin" style="margin-top:15px" (click)="goCustomer()" >Open Customer Display</button>-->
        </div>
      </div>

    </div>
    <div class="container" style="margin-top:20px;">
      <app-skippedtoken [AllSkippedTokens]="allSkippedTokensList" (SkippedTokenEvent)="skipped_token_fun($event)" ></app-skippedtoken>
      
    </div>
  </div>
</form>
