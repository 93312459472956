import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { Category } from '../../shared/components/QMSToken/Category';
import { TokenCount } from '../../shared/components/QMSToken/TokenCount';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProcessFields } from '../../shared/models/processfields-model';
import { QMSTokenModel } from '../../shared/components/QMSToken/QMSToken';
import { OEM } from '../../shared/components/QMSToken/OEM';
import { Injectable } from '@angular/core';
import { UtilityService } from '../../shared/services/utility.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Configuration } from 'src/app/shared/roots/configuration.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from '../../shared/services/global.service';
import { Brands } from '../../shared/models/brand-model'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {
  constructor(private dataService: DataService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private configuration: Configuration,
    private spinner: NgxSpinnerService,
    private _globalService: GlobalService
  ) {
    this._globalService.data$.subscribe(data => {
      if (data.ev && data.value) {
        if (data.value.timeZone == this.timeZone && data.value.storeID == this.storeID && data.value.applicationCode == this.applicationCode) {
          if (data.ev === 'tokenChange') {
            this.CurrentToken = data.value.currentTokenNumber;
          }
          if (data.ev === 'totalToken' && data.value.operation != "receptionist") {
            this.TotalTokenCount = data.value.tokenValue;
          }
        }
      }
    }, error => {
      console.log('Error: ' + error);
    });
  }

  @ViewChild('customerForm') public Form: NgForm;
  public categories: Category[];
  public selectedCategoryID: any = "";
  public TotalTokenCount: number = 0;
  public CurrentToken: string;
  public brands;
  public QMSToken = {} as QMSTokenModel;
  public selectedBrandID: any = "";
  public timeZone: string;
  public applicationCode: string;
  public storeID: any;
  public apiUrl: string;

  ngOnInit() {
    var requestToken = sessionStorage.getItem('requestToken');
    this.timeZone = sessionStorage.getItem('timeZone');
    this.storeID = sessionStorage.getItem('storeID');
    this.applicationCode = sessionStorage.getItem('applicationCode');
    this.apiUrl = sessionStorage.getItem('apiUrl');
    if (requestToken) {
      this.dataService.getAuthKey().subscribe(data => {
        console.log(data);
        sessionStorage.setItem('requestToken', data.toString());
        console.log(sessionStorage.getItem('requestToken'));
        console.log(this.apiUrl);
        this.getBrands();
      })
      this.getCategories();
      this.getTotalTokens();
      this.getCurrentToken();
    }
  }

  public getCategories() {
    var processFields = new ProcessFields();
    processFields.EntityID = 0;
    this.dataService.add<Category[]>('category/getCategory', processFields).subscribe(data => {
      this.categories = data;
    }, function () {
      this.utilityService.showFailMessage.fire('Error!. Please try again.');
    }
    );
  }

  public getBrands() {
    var processFields = new ProcessFields();
    //To change
    processFields.StoreID = this.storeID;
    //processFields.EntityID = 0;
    //processFields.ApiUrl = this.apiUrl;
    //this.dataService.addExternal<OEM[]>('userstoremapping/getuserstoreoembrandfornewuser', processFields).subscribe(data => {
    this.dataService.add<Brands[]>('QMSToken/getAllBrandsForKiosk', processFields).subscribe(data => {
      this.brands = data;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public getTotalTokens() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<TokenCount>('QMSToken/getTotalTokens', processFields).subscribe(data => {
      if (data) {
        this.TotalTokenCount = data.tokenValue;
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public getCurrentToken() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<TokenCount>('QMSToken/getCurrentToken', processFields).subscribe(data => {
      this.CurrentToken = data.currentTokenNumber;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public resetFields() {
    this.Form.reset();
    this.QMSToken = {} as QMSTokenModel;
    this.selectedCategoryID = "";
    this.selectedBrandID = "";
  }

  public SaveToken() {
    this.QMSToken.CategoryID = this.selectedCategoryID;
    this.QMSToken.BrandID = this.selectedBrandID;
    this.QMSToken.StatusCode = "OPEN";
    this.QMSToken.TimeZone = this.timeZone;
    this.QMSToken.StoreID = this.storeID;
    this.QMSToken.ApplicationCode = this.applicationCode;
    for (var i in this.brands) {
      if (this.brands[i].brandID == this.selectedBrandID) {
        this.QMSToken.brandName = this.brands[i].brandName;
        //this.QMSToken.BrandCode = this.brands[i].oemCode;
      }
    }
    this.dataService.add<any>('QMSToken/saveToken', this.QMSToken).subscribe(data => {
      if (data.toString().indexOf("Success") >= 0) {
        let Token = data.toString().split("|");
        let TokenNumber = Token[1];
        this.resetFields();
        this.getTotalTokens();
        this.getCurrentToken();
        this.utilityService.showSaveSuccessMessage("Your Token Number is " + TokenNumber);
      }
      else {
        alert(data);
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }
}
