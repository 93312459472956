import { Component, OnInit, Injectable, EventEmitter } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { UtilityService } from '../../shared/services/utility.service';
import { Category } from '../../shared/components/QMSToken/Category';
import { TokenCount } from '../../shared/components/QMSToken/TokenCount';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProcessFields } from '../../shared/models/processfields-model';
import { QMSTokenModel } from '../../shared/components/QMSToken/QMSToken';
import { Token } from '@angular/compiler';
import { GlobalService } from '../../shared/services/global.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as signalR from '@aspnet/signalr';
import { Stores } from '../../shared/models/store-model'
import { Counters } from 'src/app/shared/models/counter-model'; 

@Component({
  selector: 'app-receptionist',
  templateUrl: './receptionist.component.html',
  styleUrls: ['./receptionist.component.css']
})
@Injectable()
export class ReceptionistComponent implements OnInit {
  public allSkippedTokensList;
  public skipped_token_no;
  public isShow: boolean = false;
  storelist;
  constructor(private route: Router,private dataService: DataService
    , private utilityService: UtilityService
    , private _globalService: GlobalService
  ) {
    this._globalService.data$.subscribe(data => {
      if (data.ev && data.value) {
        if (data.value.timeZone == this.timeZone && data.value.storeID == this.storeID && data.value.applicationCode == this.applicationCode) {
          if (data.ev === 'tokenChange') {
            this.CurrentToken = data.value.currentTokenNumber;
          }
          if (data.ev === 'totalToken' && data.value.operation == "receptionist") {
            this.TotalTokenCount = data.value.tokenValue;
          }
          if (data.ev === 'getNextTokenValue' && data.value.tokenNo == this.CurrentToken) {
            this.CurrentToken = data.value.currentTokenNumber;
          }
        }
      }
    }, error => {
      console.log('Error: ' + error);
    });
  }

  //event handler for the select element's change event
  selectCategoryChangeHandler(event: any) {
    //update the ui
    this.getTotalTokens();
  }

  public ticketCount: any;
  public result: any;
  public categories: any[];
  public selectedCategoryID: any = "";
  public TotalTokenCount: number = 0;
  public QMSToken = {} as QMSTokenModel;
  public selectedBrandID: any = "";
  public isUpdateOperationVisible: boolean = true;
  public isGetOperationVisible: boolean = true;
  public ShowLabels: boolean = false;
  public isClosed: number = 0;
  public pauseNextToken: boolean = false;
  public timeZone: string;
  public storeID: any;
  public applicationCode: string;
  public CurrentToken: any;
  public emailId: string;
  public allSkippedTokens: any;
  public isActiveClicked: boolean = true;
  public selectedCounterID: any = "";
  public selectedCounterNo: any;
  public selectedStoreID: any = "";
  public allStores;
  public allCounters;
  public counters: any[];
  public Showbutton: boolean = false;
  public isDropdownVisible: boolean = true;
  public counterID: any;
  public categoryName: any;
  ngOnInit() {
    localStorage.removeItem("Selected_Counter");
    var requestToken = sessionStorage.getItem('requestToken');
    this.timeZone = sessionStorage.getItem('timeZone');
    this.storeID = sessionStorage.getItem('storeID');
    this.applicationCode = sessionStorage.getItem('applicationCode');
    this.emailId = sessionStorage.getItem('emailId');
    this.getAllStores();
    this.getAllCounters(this.storeID);
    if (requestToken) {
      this.getCategories();
      //this.getCounters();
    this.getTotalTokens();
    }
    this._globalService.data$.subscribe(data => {
      if (data.ev && data.ev === 'showSkippedTokenList') {
        this.isShow = data.value;
        
      }
    }, error => {
      console.log('Error: ' + error);
      });
  }

  public GetNextToken() {
    this.resetFields();
    var processFields = new ProcessFields();
    processFields.EntityID = this.selectedCategoryID;
    processFields.ChildEntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ApplicationCode = this.applicationCode;
    processFields.UserEmail = this.emailId;
    processFields.StoreID = this.storeID;
    processFields.CounterID = this.selectedCounterID;
    processFields.CategoryName = this.categoryName;
    console.log(processFields.userEmail);
    this._globalService.dataBusChanged('showSkippedTokenList', false);
    this._globalService.dataBusChanged('getNextTokenValue', true);
    this.dataService.add<QMSTokenModel>('QMSToken/getNextToken', processFields).subscribe(data => {
      
      if (data != null) {
        this.QMSToken = data;
        this.ShowLabels = true;
        this.isGetOperationVisible = true;
        this.isUpdateOperationVisible = false;
        this.isActiveClicked = true;
        this.Showbutton = false;
        this.updateToken();
        this._globalService.dataBusChanged('getNextTokenValue', true);
        console.log(data);
      }
      else {
        this.utilityService.showAlertForMessage('Open Token not available for selected category!');
        this.ShowLabels = false;
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }
  public skipped_token_fun(skipped_token_no) {
    this.skipped_token_no = skipped_token_no[0];
    this.ShowLabels = true;
    this.QMSToken.tokenNo = skipped_token_no[0];
    this.QMSToken.mobileNo = skipped_token_no[1];
    this.QMSToken.customerName = skipped_token_no[2];
    this.QMSToken.brandName = skipped_token_no[3];
    this.QMSToken.tokenID = skipped_token_no[4];
    this.QMSToken.categoryName = skipped_token_no[5];
    this.QMSToken.StatusCode = "SKIPPED";
    this.QMSToken.StoreID = this.storeID;
    this.QMSToken.EmailID = this.emailId;
    this.QMSToken.CounterNo = this.selectedCounterNo;
    this.isActiveClicked = true;
    this.isGetOperationVisible = true;
    this.isUpdateOperationVisible = false;
    //console.log(skipped_token_no);
    this.dataService.add<QMSTokenModel>('QMSToken/getskippedTokens', this.QMSToken).subscribe(data => {
      
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
    this.dataService.add<QMSTokenModel>('QMSToken/statusCounterChange', this.QMSToken).subscribe(data => {
      this.Showbutton = false;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }
 
  public updateToken() {
    this.QMSToken.StatusCode = "INPROGRESS";
    this.QMSToken.StoreID = this.storeID;
    this.QMSToken.EmailID = this.emailId;
    this.QMSToken.CounterNo = this.selectedCounterNo;
    this.dataService.add<any>('QMSToken/updateToken', this.QMSToken).subscribe(data => {
      if (data.toString().indexOf("Success") >= 0) {
        this.getTotalTokens();
        this.getCurrentToken();
      }
      else {
        alert(data);
      }
    }, function () {
      this.utilityService.showFailMessage('Error closing token. Please try again.');
    }
    );
  }

  public resetFields() {
    this.QMSToken = {} as QMSTokenModel;
    this.isClosed = 0;
  }

  public getCategories() {
    var processFields = new ProcessFields();
    processFields.EntityID = 0;
    this.dataService.add<any>('category/getCategory', processFields).subscribe(data => {
      this.categories = data;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public getTotalTokens() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ChildEntityKey = 'receptionist';
    processFields.ChildEntityID = this.selectedCategoryID;
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<TokenCount>('QMSToken/getTotalTokens', processFields).subscribe(data => {
      this.TotalTokenCount = data.tokenValue;
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public CloseToken() {
    if (this.QMSToken != undefined) {
      this.utilityService.showConfirmationMessage('Are your sure you want to Close Token?').then((result) => {
        if (result.value)
          this.onConfirmCloseToken();
      });
    }
  }

  public onConfirmCloseToken() {
    this.QMSToken.StatusCode = "CLOSED";
    this.QMSToken.StoreID = this.storeID;
    this.QMSToken.EmailID = this.emailId;
    this.QMSToken.CounterNo = this.selectedCounterNo;
    this.dataService.add<any>('QMSToken/closeToken', this.QMSToken).subscribe(data => {
      if (data.toString().indexOf("Success") >= 0) {
        //this.utilityService.showSaveSuccessMessage("Token closed!");
        this.getTotalTokens();
        this.isClosed = 1;
        this.isUpdateOperationVisible = true;
        this.isGetOperationVisible = false;
        this.isActiveClicked = false;
        this.Showbutton = true;
        //If checkbox checked, do not get next token automatically
        if (!this.pauseNextToken) {
          this.GetNextToken();
          this.isDropdownVisible = false;
          this.isGetOperationVisible = false;
          this.Showbutton = true;
        }
      }
      else {
        alert(data);
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }


  public SkipToken() {
    if (this.QMSToken != undefined) {
      this.utilityService.showConfirmationMessage('Are your sure you want to Skip Token?').then((result) => {
        if (result.value)
          this.onConfirmSkipToken();
      });
    }
  }

  public onConfirmSkipToken() {
    this.QMSToken.StatusCode = "SKIPPED";
    this.QMSToken.StoreID = this.storeID;
    this.QMSToken.EmailID = this.emailId;
    //this.QMSToken.Action = 1;
    this.dataService.add<Token>('QMSToken/skipToken', this.QMSToken).subscribe(data => {
      this.result = data;
      if (this.result == 'Success') {
        //this.utilityService.showSaveSuccessMessage("Token skipped!");
        this.getTotalTokens();
        this.isClosed = 1;
        this.isUpdateOperationVisible = true;
        this.isGetOperationVisible = false;
        this.isActiveClicked = false;
        this.Showbutton = true;
        //If checkbox checked, do not get next token automatically
        if (!this.pauseNextToken) {
          this.GetNextToken();
          this.isDropdownVisible = false;
          this.isGetOperationVisible = false;
          this.Showbutton = true;
        }
      }
        else {
          this.utilityService.showFailMessage("Error!.Please try again.");
      }
    });
  }

  public getCurrentToken() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;;
    processFields.EntityKey = this.timeZone;
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<TokenCount>('QMSToken/getCurrentToken', processFields).subscribe(data => {
      if (data) {
        this.CurrentToken = data.currentTokenNumber;
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public getSkippedTokens() {
    
    this.resetFields();
    var processFields = new ProcessFields();
    processFields.EntityID = this.selectedCategoryID;
    processFields.ChildEntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ApplicationCode = this.applicationCode;
    processFields.UserEmail = this.emailId;
    processFields.CategoryName = this.categoryName;

    console.log(processFields.userEmail);

    this.dataService.add<QMSTokenModel>('QMSToken/skipTokenList', processFields).subscribe(data => {
      if (data != undefined) {
        this.allSkippedTokensList = data;
        this._globalService.dataBusChanged('showSkippedTokenList', true);
      }
      else {
        this.utilityService.showAlertForMessage('Open Token not available for selected category!');
        this.ShowLabels = false;
      }
    }, function () {
      this.utilityService.showFailMessage('Error!. Please try again.');
    }
    );
  }

  public getAllStores() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ChildEntityKey = 'receptionist';
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<Stores>('QMSToken/getAllStores', processFields).subscribe(data => {
      this.allStores = data;
      console.log(data);
    },
      error => {
        console.log(error);
      }
    );
  }
  public onStoreChange(id) {
    this.getAllCounters(id);
    
  }

  //Get all counters in particular store
  public getAllCounters(id) {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.StoreID = id;
    processFields.EntityKey = "Open";
    processFields.ChildEntityKey = 'receptionist';
    processFields.ApplicationCode = this.applicationCode;
    this.QMSToken.Action = 1;
    this.dataService.add<Counters>('QMSToken/getAllCounters', processFields).subscribe(data => {
      this.allCounters = data;
      console.log(data);
    },
      error => {
        console.log(error);
      }
    );
  }

    //To select counter for receptionist to process tokens
    // Action = 1 (Counter Active)
    // Action = 0 (Counter Close)
  public onCounterChange(value) {
    
    if (this.onCounterChange != undefined) {
      this.utilityService.showConfirmationMessage('Are your sure you want to Enter this Counter?').then((result) => {
        if (result.value) {
          this.Showbutton = true;
          this.QMSToken.CounterID = this.selectedCounterID;
          this.QMSToken.StoreID = this.storeID;
          this.QMSToken.Action = 1;

          if (this.selectedCounterID != parseInt(localStorage.getItem("Selected_Counter"))) {
            this.dataService.add<QMSTokenModel>('QMSToken/updateCounter', this.QMSToken).subscribe(data => {
              this.result = data;
              this.selectedCounterNo = this.result["counterNo"];

              if (this.result["result"] == 'Success') {
                localStorage.setItem("Selected_Counter", this.selectedCounterID);
                this.isDropdownVisible = false;
                this.isGetOperationVisible = false;
              }
              else {
                this.utilityService.showFailMessage("Counter is already taken! Please select another counter");
                this.isDropdownVisible = true;
                this.Showbutton = false;
                this.selectedCounterID = "";
              }
            });
          } else {
            this.utilityService.showFailMessage("This Counter is already Taken! Please Select Another Counter");
            this.Showbutton = false;
            this.selectedCounterID = "";
          }
        }
        else {
          this.selectedCounterID = "";
        }
      });
    }
  }

    // To close counter for receptionist and stop processing tokens
  public onCounterclose() {
    if (this.onCounterclose != undefined) {
      this.utilityService.showConfirmationMessage('Are your sure you want to Close this Counter?').then((result) => {
        if (result.value) {
          this.QMSToken.CounterID = this.selectedCounterID;
          this.QMSToken.StoreID = this.storeID;
          this.QMSToken.Action = 0;
          this.dataService.add<QMSTokenModel>('QMSToken/updateCounter', this.QMSToken).subscribe(data => {
            this.result = data;
            this.selectedCounterNo = this.result["counterNo"];
            if (this.result["result"] == 'Success') {
              this.isDropdownVisible = true;
              this.Showbutton = false;
              this.isDropdownVisible = true;
              this.isGetOperationVisible = true;
              this.isActiveClicked = true;
              this.isUpdateOperationVisible = true;
              this._globalService.dataBusChanged('showSkippedTokenList', false);
              this.ShowLabels = false;
              this.selectedCounterID = "";
              localStorage.removeItem("Selected_Counter");
            }
            else {
              this.utilityService.showFailMessage("Error!.Please try again.");
              this.isDropdownVisible = true;
              this.Showbutton = false;
            }
          });
        }
      });
    }
  }
}
