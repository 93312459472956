import { DataService } from '../shared/services/data.service';
import { UtilityService } from '../shared/services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Configuration } from 'src/app/shared/roots/configuration.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-authentication',
  templateUrl: '../app.component.html'
})

export class AuthenticationComponent implements OnInit {
  constructor(private dataService: DataService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private configuration: Configuration,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  public timeZone: string;
  public storeID: any;
  public pageURL: string;
  public applicationCode: string;
  public apiUrl: string;
  public emailId: string;

  ngOnInit() {
    var requestToken = this.route.snapshot.queryParamMap.get('request');
    this.timeZone = this.route.snapshot.queryParamMap.get('timeZone');
    this.storeID = this.route.snapshot.queryParamMap.get('storeID');
    this.pageURL = this.route.snapshot.queryParamMap.get('pagename');
    this.applicationCode = this.route.snapshot.queryParamMap.get('applicationCode');
    this.apiUrl = this.route.snapshot.queryParamMap.get('apiurl');
    this.emailId = this.route.snapshot.queryParamMap.get('EmailId');

    if (requestToken) {
      sessionStorage.setItem('requestToken', this.route.snapshot.queryParamMap.get('request'));
      sessionStorage.setItem('timeZone', this.route.snapshot.queryParamMap.get('timeZone'));
      sessionStorage.setItem('storeID', this.route.snapshot.queryParamMap.get('storeID'));
      sessionStorage.setItem('applicationCode', this.route.snapshot.queryParamMap.get('applicationCode'));
      sessionStorage.setItem('apiUrl', this.route.snapshot.queryParamMap.get('apiurl'));
      sessionStorage.setItem('emailId', this.route.snapshot.queryParamMap.get('EmailId'));
      var _context = this;
      if (this.pageURL.toString().toLowerCase() == 'receptionist') {
        _context.router.navigate(['receptionist']);
      }
      else if (this.pageURL.toString().toLowerCase() == 'kioskpage') {
        _context.router.navigate(['customer']);
      }
      else if (this.pageURL.toString().toLowerCase() == 'displaytoken') {
        _context.router.navigate(['displayToken/' + this.route.snapshot.queryParamMap.get('storeID')]);
      }
      else if (this.pageURL.toString().toLowerCase() == 'releasecounters') {
        _context.router.navigate(['releaseCounters']);
      }
      else if (this.pageURL.toString().toLowerCase() == 'appointment') {
        _context.router.navigate(['appointment']);
      }
      else if (this.pageURL.toString().toLowerCase() == 'holidayloader') {
        _context.router.navigate(['holidayLoader']);
      }
      else if (this.pageURL.toString().toLowerCase() == 'cancelappointment') {
        _context.router.navigate(['cancelappointment']);
      }
    }
    else {
      _context.router.navigate(['errorPage']);
    }
  }
}
