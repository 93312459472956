import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

@Injectable()
export class UtilityService {

  public _translate: TranslateService;
  constructor(translate: TranslateService) {
    this._translate = translate;
  }

  public showSaveSuccessMessage(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.SaveSuccess')
    }
    swal.fire({
      title: 'Success', text: message, type: 'success', confirmButtonText: 'Close'
    });
  }

  public showUpdateSuccessMessage(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.UpdateSuccess');
    }
    swal.fire({
      title: 'Success', text: message, type: 'success', confirmButtonText: 'Close'
    });
  }

  public showSubmitSuccessMessage(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.SubmitSuccess');
    }
    swal.fire({
      title: 'Success', text: message, type: 'success', confirmButtonText: 'Close'
    });
  }

  public showAppointmentSuccessMessage(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.SubmitSuccess');
    }
    swal.fire({
      title: 'Thanks for booking Appointment', text: message, type: 'success', confirmButtonText: 'Close'
    });
  }


  public showDeleteSuccessMessage(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.DeleteSuccess');
    }
    swal.fire({
      title: 'Success', text: message, type: 'success', confirmButtonText: 'Close'
    });
  }

  public showFailMessage(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Error.Error');
    }
    swal.fire({
      title: 'Failure', text: message, type: 'warning', confirmButtonText: 'Close'
    });
  }

  public showSelectMessageToProceed(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.AlertMessages.Select_Record_Proceed');
    }
    swal.fire({
      title: 'Failure', text: message, type: 'warning', confirmButtonText: 'Close'
    });
  }

  public showSelectMessageToDelete(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.AlertMessages.Select_Record_Delete');
    }
    swal.fire({
      title: 'Failure', text: message, type: 'warning', confirmButtonText: 'Close'
    });
  }

  public showSelectMessageToVoid(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.AlertMessages.Select_Record_Void');
    }
    swal.fire({
      title: 'Failure', text: message, type: 'warning', confirmButtonText: 'Close'
    });
  }

  public showSelectMessageToEdit(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.AlertMessages.Select_Record_Edit');
    }
    swal.fire({
      title: 'Failure', text: message, type: 'warning', confirmButtonText: 'Close'
    });
  }

  public showSelectMessageToView(message?: string) {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.AlertMessages.Select_Record_View');
    }
    swal.fire({
      title: 'Failure', text: message, type: 'warning', confirmButtonText: 'Close'
    });
  }

  public showConfirmationMessage(message?: string): any {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.SaveSuccess')
    }
    return swal.fire({
      title: 'Confirmation',
      text: message,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    })

  }

  public showConfirmationMessageForVoid(): any {
    return swal.fire({
      title: 'Confirmation',
      text: this._translate.instant('Common.Message.Confirmation.Void'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    })
  }

  public showAlertForMessage(message?: string): any {
    if (message == undefined) {
      message = this._translate.instant('Common.Message.Result.SaveSuccess')
    }
    return swal.fire({
      title: 'No Record Found!',
      text: message,
      type: 'info',
      confirmButtonText: 'Close'
    })
  }

  public showDataClearConfirmationMessage(message?: string): any {
    if (message == undefined) {
      message = this._translate.instant('FormsConfiguration.Message.Confirmation.Clear');
    }
    return swal.fire({
      title: 'Confirmation',
      text: message,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    })
  }

  public toCamelCase(value: string) {
    return value
      .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
  }

  public validateEntities(source, data) {
    var result = true;
    var property = 'border';
    var stylefailed = '0.5px solid red';
    var stylesuccess = '0.5px solid #cccccc';
    var isFocusSet = false;
    var validatefields = [];

    if (source != null) {
      source.forEach(configObject => {

        if (configObject != null) {
          if (!configObject.isDisable && configObject.isMandatory && configObject.isVisible) {
            var ctrl = data['' + this.toCamelCase(configObject.modelName)];
            if (configObject.controlTypeID != null && configObject.controlTypeID == 13)//List box validation
            {
              if (ctrl == null || ctrl == undefined) {
                result = false;
                validatefields.push(configObject.captionName + " Field value should be selected from list.");
              }
              else if (ctrl.length > 0 && ctrl.length == 0) {
                result = false;
                validatefields.push(configObject.captionName + " Field value should be selected from list.");
              }
            }
            else if (ctrl != null) {
              var value = ctrl;
              if (value != null) {
                value = value.toString().trim();
              }
              if (value != undefined) {
                if (configObject.controlTypeID != 13) {
                  if (ctrl.length > 0) {
                    if (configObject.controlTypeID == 2) {
                      if (!ctrl) {
                        result = false;
                        validatefields.push(configObject.captionName + " Field should not be empty");
                      }
                    }
                    else {
                      if (value.length <= 0) {
                        result = false;
                        validatefields.push(configObject.captionName + " Field should not be empty");
                      }

                      else if (value.length > configObject.maximumLength || value.length < configObject.minimumLength) {
                        result = false;
                        validatefields.push(configObject.captionName + " Field length should be greater than or equal to  " + configObject.minimumLength + " and less than or equal to " + configObject.maximumLength + ".");

                      }
                      else if (value.length <= configObject.maximumLength && value.length >= configObject.minimumLength) {
                        var expressionRule = new RegExp(configObject.fieldRule);

                        if (!expressionRule.test(value)) {
                          result = false;
                          switch (configObject.regExCode) {
                            case "EmailIDvalidation": {
                              validatefields.push(configObject.captionName + " is not in correct email format");
                              break;
                            }
                            case "SerialNumbervalidation": {
                              validatefields.push(configObject.captionName + " is not a valid serial number");
                              break;
                            }
                            case "PhoneorMobilenumbervalidation": {
                              validatefields.push(configObject.captionName + " is not a valid 10 digit phone or mobile no");
                              break;
                            }
                            case "ZIPcodevalidation": {
                              validatefields.push(configObject.captionName + " should contain 6 digits");
                              break;
                            }
                            case "Numeric12digitmandatoryvalidation": {
                              validatefields.push(configObject.captionName + " should be a 12 digit number only");
                              break;
                            }
                            case "MoneyValidation": {
                              validatefields.push(configObject.captionName + " should be rounded up or should have valid amount. ");
                              break;
                            }
                            case "AlphanumericValidation": {
                              validatefields.push(configObject.captionName + " should consist only alphabets and/or numbers");
                              break;
                            }
                            case "NumericValidation": {
                              validatefields.push(configObject.captionName + " should only be numbers ");
                              break;
                            }
                            case "AlphabeticValidation": {
                              validatefields.push(configObject.captionName + " is in invalid format enter alphabets only !");
                              break;
                            }
                            case "Addressfieldvalidation": {
                              validatefields.push(configObject.captionName + " is in invalid address format");
                              break;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                else {
                  if (ctrl[0].selectedIndex <= 0) {
                    if (value.length <= 0 || (value.substring(0, 1) == "?" && value.substring(value.length - 1, value.length) == "?")) {
                      result = false;
                    }
                  }
                }
              }
            }
            else if (ctrl == undefined) {
              result = false;
              validatefields.push(configObject.captionName + " Field should not be empty");
            }
          }
        }
      });
    }
    return validatefields;
  }

}
