<header class="header">
  <ul class="topnav">
    <li class="flex-logo-div" style="float:left"><h1>FLEXLINK</h1></li>
    <li style="float:right"><a class="active">Welcome User!</a></li>
  </ul>
</header>
<form id="Customer" name="CustomerForm" #customerForm="ngForm">
  <div class="controls">
    <div class="card mt-5 myForm col-lg-12 col-md-12 col-sm-12">
      <div class="row">
        <!--<div class="col-lg-3 col-md-3 col-sm-3">
    </div>-->
        <div class="col-lg-5 col-md-5 col-sm-6">
          <div class="form-group" style="float:right">
            <label for="form_currentToken">Current Token</label><br />
            <span class="tokenBadge">{{CurrentToken}}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-2">
        </div>
        <div class="col-lg-5 col-md-5 col-sm-6">
          <div class="form-group">
            <label for="form_totalTokens">Total Tokens</label><br />
            <span class="tokenBadge">{{TotalTokenCount}}</span>
          </div>
        </div>
        <!--<div class="col-lg-2 col-md-2 col-sm-2">
    </div>-->
      </div>
      <!--</div>
  <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 1%;">-->
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 divSpacing">
          <div class="form-group" [class.has-error]="categoryControl.invalid && categoryControl.touched" [class.has-success]="categoryControl.valid">
            <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
            <label for="form_categoryname"><span style="color:red;">*</span>Category</label>
            <!--</div>-->
            <!--<div class="col-lg-5 col-md-5 col-sm-5">-->
            <select class="form-control col-lg-2 col-md-2 col-sm-2" name="categoryname" #categoryControl="ngModel" id="form_categoryname"
                    [(ngModel)]="selectedCategoryID" required>
              <option value="">--SELECT--</option>
              <option *ngFor="let cat of categories" [value]="cat.categoryID">{{cat.categoryName}}</option>
            </select>
            <div *ngIf="categoryControl.invalid && (categoryControl.dirty || categoryControl.touched)" class="alert alert-danger">
              <span *ngIf="categoryControl.errors.required">
                Category is required.
              </span>
            </div>
            <!--</div>-->
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 divSpacing">
          <div class="form-group" [class.has-error]="brandControl.invalid && brandControl.touched" [class.has-success]="brandControl.valid">
            <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
            <label for="form_brandname"><span style="color:red;">*</span>Brand Name</label>
            <select class="form-control col-lg-2 col-md-2 col-sm-2" name="form_brandname" #brandControl="ngModel" id="form_brandname"
                    [(ngModel)]="selectedBrandID" required>
              <option value="">--SELECT--</option>
              <option *ngFor="let brand of brands" [value]="brand.brandID">{{brand.brandName}}</option>
            </select>
            <div *ngIf="brandControl.invalid && (brandControl.dirty || brandControl.touched)" class="alert alert-danger">
              <span *ngIf="brandControl.errors.required">
                Brand is required.
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 divSpacing">
          <div class="form-group">
            <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
            <label for="form_customername"><span style="color:red;">*</span>Customer Name</label>
            <input id="form_customername" type="text" [(ngModel)]="QMSToken.CustomerName" name="customerName" class="form-control" placeholder="Please enter your Name *" autocomplete="off" #customernameControl="ngModel" maxlength="100" required>
            <div *ngIf="customernameControl.invalid && (customernameControl.dirty || customernameControl.touched)" class="alert alert-danger">
              <span *ngIf="customernameControl.errors.required">
                Name is required.
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 divSpacing">
          <div class="form-group" [class.has-error]="mobilenoControl.invalid && mobilenoControl.touched" [class.has-success]="mobilenoControl.valid">
            <!--<div class="col-lg-3 col-md-3 col-sm-3">-->
            <label for="form_mobilenumber"><span style="color:red;">*</span>Mobile Number</label>
            <input id="form_mobilenumber" type="text" [(ngModel)]="QMSToken.MobileNo" name="mobilenumber" class="form-control" placeholder="Please enter your Mobile Number *" autocomplete="off" #mobilenoControl="ngModel" maxlength="10"
                   pattern="^[1-9][0-9]{9}$" required>
            <div *ngIf="mobilenoControl.invalid && (mobilenoControl.dirty || mobilenoControl.touched)" class="alert alert-danger">
              <span *ngIf="mobilenoControl.errors.required">
                Mobile Number is required.
              </span>
              <span *ngIf="mobilenoControl.errors.pattern">
                Mobile Number is invalid.
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
        </div>
      </div>
      <div class="row" style="margin-top: 1%;">
        <div class="col-lg-5 col-md-5 col-sm-5">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2" style="margin-left: 2%;">
          <!--<span data-title="Please fill all mandatory details!">-->
          <input type="submit" title="Please fill all mandatory details!" class="btn btn-primary" value="Generate Token" [disabled]="customerForm.pristine || customerForm.invalid" (click)="SaveToken()">
          <!--</span>-->
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5">
        </div>
      </div>
    </div>
  </div>
</form>
