import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProcessFields } from '../models/processfields-model';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map'

@Injectable()
export class Configuration {
  public ApplicationDetails: any;

  constructor(private http: Http) {
    this.readAppSettings();
  }

  load(value?: any) {
    return new Promise((resolve, reject) => {
      var processField = new ProcessFields();
      processField.RequestToken = sessionStorage.getItem('requestToken');
      if (value)
        processField.RequestToken = value;
      if(processField.RequestToken){
      var processFields = JSON.stringify(processField);
      this.http.get(this.server + 'helper/getApplicationDetails', {
        params: {
          processFields
        }
      }).subscribe(data => {
        this.ApplicationDetails = data;
        console.log(data);
        resolve(true);
      });
    }
    });
  }

  public server = '';
  public apiUrl = '';
  public serverWithApiUrl = '';
  //public externalApiUrl = 'https://fl20commondev.azurewebsites.net/dev/commonapi/';
  public externalApiUrl = sessionStorage.getItem('apiUrl');//'https://flcoretest.azurewebsites.net/dev/commonapi/';
  //public externalApiUrl = 'http://localhost:20471/';
  public Code;
  public Key;
  public GetStoreId_Appointment_Flexlink_URL;
  public GetAuthKey_Appointment_URL;

  public pageName: any = {
    CommonMaster: "CommonMaster",
    GridFieldDefinition: "GridFieldDefinition",
  };

  async readAppSettings() {
    await this.http.get('appsettings.json').map(res => res.json()).toPromise().then(data => {
      this.Code = atob(data.ServerAPI_Code);
      this.Key = atob(data.ServerAPI_Key);
      this.GetStoreId_Appointment_Flexlink_URL = data.GetStoreId_Appointment_Flexlink_URL;
      this.GetAuthKey_Appointment_URL = data.GetAuthKey_Appointment_URL;
      this.server = data.ServerApiURL;
      this.serverWithApiUrl = this.server + this.apiUrl;
    });
  }
}


