import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(it => {
      if (it == '' || it == null)
        return items;

      if ("undefined" != typeof (it["fieldName"])) {
        return it.fieldName.toLowerCase().includes(searchText);
      }
      else if ("undefined" != typeof (it["columnName"])) {
        return it.columnName.toLowerCase().includes(searchText);
      }
    });
  }

}
