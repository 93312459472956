<header class="header">
  <ul class="topnav">
    <li class="flex-logo-div" style="float:left"><h1>FLEXLINK</h1></li>
    <li style="float:right"><a class="active">Welcome User!</a></li>
  </ul>
</header>
<div class="row">
  <div class="row col-md-12" style="text-align:left;">
    <div class="col-md-9">
      <div ng2FileDrop [class.isDisabled]="selectedFile.length==1" [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="well my-drop-zone">
        <input ng-disabled="selectedFile.length==1" type="file" ng2FileSelect [uploader]="uploader" />
      </div>
    </div>
    <div class="col-md-3">
      <input type="image" src="assets/Images/ExcelDownloadImage.gif" alt="Template" (click)="downloadTemplate()" width="42" height="42">
      <span>Download_Template</span>
    </div>
  </div>
  <div class="col-md-12">
    <!--Summary-->
    <!--<app-grid [gridData]="purgeSummary" [columns]="purgeSummaryColumnsData">
    </app-grid>-->
    <div>
      <button type="button" class="btn btn-success btn-s" (click)="uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
        <span class="glyphicon glyphicon-upload">Upload</span>
      </button>
      &nbsp;&nbsp;
      <!--<button type="button" class="btn btn-danger btn-s" (click)="clearQueue()">
        <span class="glyphicon glyphicon-trash">Cancel</span>
      </button>-->
    </div>
  </div>
</div>








