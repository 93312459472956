import { Component, OnInit, Injectable, EventEmitter } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { UtilityService } from '../../shared/services/utility.service';
import { ProcessFields } from '../../shared/models/processfields-model';
import { QMSTokenModel } from '../../shared/components/QMSToken/QMSToken';
import { GlobalService } from '../../shared/services/global.service';
import { Router } from '@angular/router';
import { Stores } from '../../shared/models/store-model'
import { Counters } from 'src/app/shared/models/counter-model';

@Component({
  selector: 'app-releaseCounters',
  templateUrl: './release-counters.component.html',
  styleUrls: ['./release-counters.component.css']
})
@Injectable()
export class ReleaseCountersComponent implements OnInit {
  public isShow: boolean = false;
  storelist;
  constructor(private route: Router, private dataService: DataService
    , private utilityService: UtilityService
    , private _globalService: GlobalService
  ) {
  }

 
  public result: any;
  public QMSToken = {} as QMSTokenModel;
  public timeZone: string;
  public storeID: any;
  public applicationCode: string;
  public CurrentToken: any;
  public emailId: string;
  public selectedCounterID: any = "";
  public selectedCounterNo: any;
  public selectedStoreID: any = "";
  public allStores;
  public allCounters;
  public counters: any[];
  public Showbutton: boolean = false;
  public isDropdownVisible: boolean = true;
  public counterID: any;
  ngOnInit() {
    var requestToken = sessionStorage.getItem('requestToken');
    this.timeZone = sessionStorage.getItem('timeZone');
    this.storeID = sessionStorage.getItem('storeID');
    this.applicationCode = sessionStorage.getItem('applicationCode');
    this.emailId = sessionStorage.getItem('emailId');
    this.getAllStores();
    this.getAllCounters(this.storeID);
    if (requestToken) { }
  }

  public getAllStores() {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.EntityKey = this.timeZone;
    processFields.ChildEntityKey = 'receptionist';
    processFields.ApplicationCode = this.applicationCode;
    this.dataService.add<Stores>('QMSToken/getAllStores', processFields).subscribe(data => {
      this.allStores = data;
      this.isDropdownVisible = false;
      console.log(data);
    },
      error => {
        console.log(error);
      }
    );
  }
  public onStoreChange(id) {
    this.getAllCounters(id);

  }
  //To get all open counters only for Admin
  public getAllCounters(id) {
    var processFields = new ProcessFields();
    processFields.EntityID = this.storeID;
    processFields.StoreID = id;
    processFields.EntityKey = "Active";
    processFields.ChildEntityKey = 'receptionist';
    processFields.ApplicationCode = this.applicationCode;
    this.isDropdownVisible = true;
    this.QMSToken.Action = 0;
    this.dataService.add<Counters>('QMSToken/getAllCounters', processFields).subscribe(data => {
      this.allCounters = data;
      console.log(data);
    },
      error => {
        console.log(error);
      }
    );
  }

  //To release all open counters only for Admin
  public onCounterChange(value) {

    if (this.onCounterChange != undefined) {
      this.utilityService.showConfirmationMessage('Are your sure you want to Release this counter?').then((result) => {
        if (result.value) {
          this.Showbutton = true;
          this.QMSToken.CounterID = this.selectedCounterID;
          this.QMSToken.StoreID = this.selectedStoreID;
          this.QMSToken.Action = 0;

              this.dataService.add<QMSTokenModel>('QMSToken/updateCounter', this.QMSToken).subscribe(data => {
              this.result = data;
              this.selectedCounterNo = this.result["counterNo"];

              if (this.result["result"] == 'Success') {
                this.utilityService.showSaveSuccessMessage("Counter released sucessfully");
                this.selectedCounterID = "";
                localStorage.removeItem("Selected_Counter");
                this.getAllCounters(this.selectedStoreID);
              }
              else {
                this.utilityService.showFailMessage("Something went wrong. Please try again!");
                this.isDropdownVisible = false;
                this.Showbutton = false;
                this.selectedCounterID = "";
              }
            });
        }
      });
    }
  }
}
