import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { Configuration } from '../roots/configuration.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';

@Injectable()
export class DataService {

  private actionUrl: string;
  private externalApiUrl: string;
  private apiUrl: string;
  
  constructor(private http: HttpClient, private configuration: Configuration, private spinner: NgxSpinnerService, private datePipe: DatePipe) {
    this.actionUrl = configuration.serverWithApiUrl;
    this.externalApiUrl = configuration.externalApiUrl;
  }

  public getAuthKey() {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinner.show();
    let date = new Date();
    this.apiUrl = sessionStorage.getItem('apiUrl');
    return this.http.get(this.apiUrl + "helper/getQMSAuthKey/" + this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC'), requestOptions);
    
  }
  public getAuthKeyAppointment() {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinner.show();
    let date = new Date();
    this.apiUrl = sessionStorage.getItem('apiUrl');
    return this.http.get(this.configuration.GetAuthKey_Appointment_URL + this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC'),requestOptions);

  }


  public getAll<T>(actionURL: string, processField: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    this.spinner.show();
    var processFields = JSON.stringify(processField);
    return this.http.get<T>(this.actionUrl + actionURL, {
      headers: {
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      params: {
        processFields
      }
    })
  }

  public getAllExternal<T>(actionURL: string, processField: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    this.spinner.show();
    var processFields = JSON.stringify(processField);
    if (!this.externalApiUrl)
      this.externalApiUrl = this.configuration.ApplicationDetails.applicationUrl;
    if (this.configuration.ApplicationDetails)
      actionURL = this.configuration.ApplicationDetails.applicationActions.filter(a => a.actionName == actionURL)[0].actionValue;
    return this.http.get<T>(this.externalApiUrl + actionURL, {
      headers: {
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      params: {
        processFields
      }
    })
  }

  public getSingle<T>(actionURL: string, id: number): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    this.spinner.show();
    return this.http.get<T>(this.actionUrl + actionURL + id, {
      headers: {
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }

  public getAllById<T>(actionURL: string, processField: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    this.spinner.show();
    var processFields = JSON.stringify(processField);
    return this.http.get<T>(this.actionUrl + actionURL, {
      headers: {
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      params: {
        processFields
      }
    })
  }

  public getAllByProcessFields<T>(actionURL: string, processField: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    this.spinner.show();
    var processFields = JSON.stringify(processField);
    return this.http.get<T>(this.actionUrl + actionURL, {
      headers: {
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      params: {
        processFields
      }
    })
  }


  public addExternal<T>(actionURL: string, itemToAdd: any): Observable<T> {
    const headerDict = {
      'Authorization': 'Basic ' + btoa(sessionStorage.getItem('requestToken')),
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    itemToAdd.requestToken = sessionStorage.getItem('requestToken');
    return this.http.post<T>(sessionStorage.getItem('apiUrl') + actionURL, itemToAdd, requestOptions);
  }
  public addFlexLink<T>(actionURL: string, itemToAdd: any): Observable<T> {
    const headerDict = {
      'Authorization': 'Basic ' + btoa(sessionStorage.getItem('requestToken')),
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    itemToAdd.requestToken = sessionStorage.getItem('requestToken');
    return this.http.post<T>(this.configuration.GetStoreId_Appointment_Flexlink_URL + actionURL, itemToAdd, requestOptions);
  }

  public add<T>(actionURL: string, itemToAdd: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    const headerDict = {
      'Authorization': 'Basic ' + token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinner.show();
    itemToAdd.requestToken = sessionStorage.getItem('requestToken');
    return this.http.post<T>(this.actionUrl + actionURL, itemToAdd, requestOptions);
  }

  public update<T>(actionURL: string, id: number, itemToUpdate: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    this.spinner.show();
    const headerDict = {
      'Authorization': 'Basic ' + token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    itemToUpdate.requestToken = sessionStorage.getItem('requestToken');
    return this.http.put<T>(this.actionUrl + actionURL + id, itemToUpdate, requestOptions);
  }

  public delete<T>(actionURL: string, id: number): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    const headerDict = {
      'Authorization': 'Basic ' + token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinner.show();
    return this.http.delete<T>(this.actionUrl + actionURL + id, requestOptions);
  }

  public deleteByProcessFields<T>(actionURL: string, processField: any): Observable<T> {
    var token = window.btoa(this.configuration.Code + ':' + this.configuration.Key);
    const headerDict = {
      'Authorization': 'Basic ' + token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinner.show();
    return this.http.post<T>(this.actionUrl + actionURL, processField, requestOptions);
  }

  public getArrayOfActions(actionFields) {
    var arrayOfActions = [];
    if (actionFields != null) {
      for (var value of actionFields) {
        arrayOfActions[value.actionName] = value;
      }
    }
    return arrayOfActions;
  }
}

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService, private configuration: Configuration) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    return next.handle(req).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        var eventUrl = event.url;
        var externalApiUrl;
        if (this.configuration.ApplicationDetails) {
          externalApiUrl = this.configuration.ApplicationDetails.applicationUrl;
          this.spinner.hide();
        }

        if (eventUrl.includes(this.configuration.server) || eventUrl.includes(externalApiUrl))
          this.spinner.hide();
      }
    }, (err: any) => {
      this.spinner.hide();
    });
  }
}
